import { COLON, DOT } from "./string-constants";

export const X: string = "x";
export const DX: string = "dx";
export const DY: string = "dy";
export const Y: string = "y";
export const G: string = "g";
export const D: string = "d";
export const A: string = "A";
export const V_: string = "v";
export const E_: string = "e";
export const V: string = "V";
export const M: string = "M";
export const Z: string = "Z";
export const SVG: string = "svg";
export const BRUSH: string = "brush";
export const BRUSH_END: string = "brushend";
export const MOUSE_MOVE: string = "mousemove";
export const RECT: string = "rect";
export const CLASS: string = "class";
export const TEXT: string = "text";
export const TEXT_ANCHOR: string = "text-anchor";
export const ANCHOR: string = "anchor";
export const TSPAN: string = "tspan";
export const MIDDLE: string = "middle";
export const FIRST_CHILD = "first-child";
export const COLON_FIRST_CHILD = COLON + FIRST_CHILD;
export const TRANSLATE: string = "translate";
export const TRANSFORM: string = "transform";
export const HEIGHT: string = "height";
export const WIDTH: string = "width";
export const PATH: string = "path";
export const IMPORTANT: string = "important";
export const TITLE: string = "title";

export const CLASS_LEFT: string = "left";
export const CLASS_RIGHT: string = "right";
export const CLASS_RESIZE: string = "resize";
export const CLASS_EXTENT: string = "extent";
export const CLASS_BACKGROUND: string = "background";

export const NV_BRUSH_BACKGROUND: string = "nv-brushBackground";

export const CLASS_ELLIPSIS: string = "ellipsis";
export const D3_SELECTOR_ELLIPSIS: string = DOT + CLASS_ELLIPSIS;
