import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";

import { switchMap } from "rxjs/operators";
import {
  MutationType
} from "../dashboard/components/config/mutation-type.enum";
import { Person } from "../model/person.model";
import { Timestamp } from "../model/timestamp.model";
import { User } from "../model/user/user.model";
import {
  DataService
} from "../services/data/data.service";
import { Noop } from "./effects";
import {
  ADD_TASK,
  AddTask,
  MutationFailed,
  MutationSuccessful,
  UPDATE_TASK,
  UpdateTask
} from "./mutation.actions";
import { DELETED, UPDATED } from "../util/string-constants";
import { CANCELLED } from "../util/graphql-tags";

const _LOG_ID = "task.effects";

// tslint:disable-next-line:max-line-length
const _ADD_TASK_GQL = `mutation (
	$By: UserInput!
	$type: String!
	$title: String!
	$description: String
  $notes: String
	$Timestamp: DateTimeInput!
	$PrimaryReference: ReferenceInput!
	$AdditionalReferences: [ReferenceInput!]
	$Owner: UserInput!
	$AssignedTo: PersonInput
	$DueTimestamp: DateTimeInput
	$priority: String
	$status: String
  $parentGUID: String
	$updateGUID: String
) {
	AddTask(
		By: $By
		type: $type
		title: $title
		description: $description
    notes: $notes
		Timestamp: $Timestamp
		PrimaryReference: $PrimaryReference
		AdditionalReferences: $AdditionalReferences
		Owner: $Owner
		AssignedTo: $AssignedTo
		DueTimestamp: $DueTimestamp
		priority: $priority
		status: $status
    parentGUID: $parentGUID
		updateGUID: $updateGUID
	) {
		guid
		id
		title
		description
	}
}
`;

const _UPDATE_TASK_GQL = `mutation (
  $guid: String!
  $By: UserInput!
  $type: String
	$title: String!
	$description: String
  $notes: String
	$Timestamp: DateTimeInput!
	$Owner: UserInput
	$AssignedTo: PersonInput
	$DueTimestamp: DateTimeInput
	$priority: String
	$status: String
  ) {
	UpdateTask(
    guid: $guid,
		By: $By
    type: $type
		title: $title
		description: $description
    notes: $notes
		Timestamp: $Timestamp
		Owner: $Owner
		AssignedTo: $AssignedTo
		DueTimestamp: $DueTimestamp
		priority: $priority
		status: $status
	) {
		guid
		id
		title
		description
	}
}
`;

@Injectable()
export class TaskEffects {

  @Effect()
  public taskAdded$ = this._action$
  .pipe(
    ofType(ADD_TASK),
    switchMap(async (action: AddTask) => {
    return this.saveTask(action);
    })
  );

  @Effect()
  public taskUpdated$ = this._action$
  .pipe(
    ofType(UPDATE_TASK),
    switchMap(async (action: UpdateTask) => {
    return this.saveUpdatedTask(action);
    })
  );

  constructor(private _action$: Actions, private _dataService: DataService) {}

  private async saveTask(action: AddTask): Promise<MutationSuccessful | MutationFailed |  Noop> {
    const task = action.task;
    const primaryReference = action.primaryReference;
    const additionalReferences = action.additionalReferences;
    const parentTask = task.Parent;

    const parentGUID = null != parentTask ? parentTask.id : null;

    console.log("NEED TO ADD THIS NEW TASK", task);

    const params = {
      By: User.ToValidInputObject(task.CreatedBy),
      type: task.Type.code,
      title: task.title,
      description: task.description || task.title,
      notes: task.notes,
      Timestamp: Timestamp.ToValidInputObject(Timestamp.now()),
      PrimaryReference: primaryReference,
      AdditionalReferences: additionalReferences,
      Owner: User.ToValidInputObject(task.Owner || task.CreatedBy),
      AssignedTo: Person.ToValidInputObject(task.AssignedTo),
      DueTimestamp: Timestamp.ToValidInputObject(task.DueTimestamp),
      priority: null != task.Priority ? task.Priority.code : null,
      status: null != task.Status ? task.Status.code : null,
      parentGUID: parentGUID,
      updateGUID: task.guid,
    };

    console.log("NEW", JSON.stringify(params, null, 2));

    try {
      await this._dataService.mutate(_LOG_ID, _ADD_TASK_GQL, params);
      console.log("TASK SAVED");

      return new MutationSuccessful([MutationType.Task], null, task.Type.code.toUpperCase());
    } catch (err) {
      console.log(`TASK __NOT__ SAVED!:

      ${err}`);
      return new MutationFailed(err, [MutationType.Task], null, task.Type.code.toUpperCase());
    }
  }


  private async saveUpdatedTask(action: UpdateTask): Promise<MutationSuccessful | MutationFailed |  Noop> {
    const task = action.task;

    const translationSuffix = CANCELLED === task.Status.code ? DELETED.toUpperCase() : UPDATED.toUpperCase();

    const params = {
      guid: task.guid,
      By: User.ToValidInputObject(task.ModifiedBy),
      type: null != task.Type ? task.Type.code : null,
      title: task.title,
      description: task.description || task.title,
      notes: task.notes,
      Timestamp: Timestamp.ToValidInputObject(Timestamp.now()),
      Owner: User.ToValidInputObject(task.Owner),
      AssignedTo: Person.ToValidInputObject(task.AssignedTo),
      DueTimestamp: Timestamp.ToValidInputObject(task.DueTimestamp),
      priority: null != task.Priority ? task.Priority.code : null,
      status: null != task.Status ? task.Status.code : null
    };

    console.log("UPDATE", JSON.stringify(params, null, 2));

    try {
      await this._dataService.mutate(_LOG_ID, _UPDATE_TASK_GQL, params);
      console.log("TASK SAVED");

      return new MutationSuccessful([MutationType.Task], translationSuffix, task.Type.code.toUpperCase());
    } catch (err) {
      console.log(`TASK __NOT__ SAVED!:

      ${err}`);
      return new MutationFailed(err, [MutationType.Task], null, task.Type.code.toUpperCase());
    }
  }

}
