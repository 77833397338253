
export class EnumValues<T> {

  public constructor(private _e: T) {}

  public get NamesAndValues(): Array<{ name: string, value: T }> {
    return this.Names.map((name) => ({ name, value: this._e[name] as T }));
  }

  public get Values(): T[] {
    return this.Names.map((name) => this._e[name]) as T[];
  }

  public get Names(): string[] {
    return Object.keys(this._e).filter((key) => isNaN(+key));
  }

  public NameFromValue(value: T): string | null {
    const all = this.NamesAndValues.filter((pair) => pair.value === value);
    return all.length === 1 ? all[0].name : null;
  }

}
