import {
  JsonObject,
  JsonProperty
} from "json2typescript";

import * as GQL from "../../util/graphql-tags";
import { BusinessDate } from '../business-dates/business-date.model';

@JsonObject
export class Period {

  public static NO_PERIOD: Period = {
    type: "NoPeriod",
    BusinessDate: null
  };

  @JsonProperty(GQL.SCALAR_TYPE, String, false)
  public type: string = undefined;

  @JsonProperty(GQL.BUSINESS_DATE, Object, false)
  public BusinessDate: BusinessDate = undefined;

}
