import { AssertionError } from "assert";
import {
  JsonConverter,
  JsonCustomConvert,
  JsonObject
} from "json2typescript";

import { FeatureKey } from "./feature-key.enum";

@JsonObject
export class Features {

  private _data: any;

  constructor(data: any = {}) {
    try {
      this._data = JSON.parse(data);
    } catch (e) {
      this._data = data;
    }
  }

  public serialize(): any {
    return this._data || {};
  }

  public feature(key: FeatureKey, throwOnMissing: boolean = true, defaultValueIfMissing: any = null): any {
    if (null != this._data && key in this._data) {
      return this._data[key];
    } else {
      if (throwOnMissing) {
        throw new AssertionError(
          {
            message: `Key '${key}' was not present in the Features.`,
            expected: "A value",
            actual: "No value"
          }
        );
      } else {
        return defaultValueIfMissing;
      }
    }
  }

}

// tslint:disable-next-line: max-classes-per-file
@JsonConverter
export class FeaturesConverter implements JsonCustomConvert<Features> {
  public serialize(data: Features): any {
    return data ? data.serialize() : null;
  }

  public deserialize(val: any): Features {
    if (null == val) {
      return null;
    }
    return new Features(val);
  }
}
