import {
  HttpClientModule
} from "@angular/common/http";
import {
  APP_INITIALIZER,
  ApplicationRef,
  NgModule
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
  BrowserModule
} from "@angular/platform-browser";
import {
  BrowserAnimationsModule
} from "@angular/platform-browser/animations";
import {
  PreloadAllModules,
  RouterModule
} from "@angular/router";
import {
  createInputTransfer,
  createNewHosts,
  removeNgStyles
} from "@angularclass/hmr";
import { EffectsModule } from "@ngrx/effects";
import {
  StoreRouterConnectingModule
} from "@ngrx/router-store";
import {
  META_REDUCERS,
  StoreModule
} from "@ngrx/store";
import {
  StoreDevtoolsModule
} from "@ngrx/store-devtools";
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule
} from "@ngx-translate/core";
import {
  DataTablesModule
} from "angular-datatables";
import {
  Apollo,
  ApolloModule
} from "apollo-angular";
import {
  HttpLinkModule
} from "apollo-angular-link-http";
import {
  BsDatepickerModule
} from "ngx-bootstrap";

import { AppComponent } from "./app.component";
import { AppConfig } from "./app.config";
import {
  APP_RESOLVER_PROVIDERS
} from "./app.resolver";
import { ROUTES } from "./app.routes";
import {
  AppState,
  InternalStateType
} from "./app.service";
import {
  AUTH_GUARD_PROVIDERS
} from "./auth.guards";
import { reducers } from "./context/context";
import { Effects } from "./context/effects";
import {
  getMetaReducers
} from "./context/meta.reducers";
import { ENV_PROVIDERS } from "./environment";
import {
  ErrorComponent
} from "./error/error.component";
import {
  AuthenticationService
} from "./services/auth/authentication.service";
import {
  BrandService
} from "./services/brand/brand.service";
import {
  DashboardService
} from "./services/dashboard/dashboard.service";
import {
  DataService
} from "./services/data/data.service";
import {
  FormatterService
} from "./services/formatter/formatter.service";
import {
  DataPreparationService
} from "./services/data-preparation/data-preparation.service";
import {
  CustomGraphQLTranslateLoader
} from "./services/locale/custom-graphql-translation-loader";
import {
  LocaleService
} from "./services/locale/locale.service";
import {
  I18nMissingTranslationHandler
} from "./services/locale/missingTranslationHandler.service";
import {
  GlobalErrorHandler
} from "./services/logging/global-error-handler";
import {
  Logger
} from "./services/logging/logger";
import {
  LoggingModule
} from "./services/logging/logging.module";
import {
  LoggingService
} from "./services/logging/logging.service";
import {
  NotificationsService
} from "./services/notifications/notifications.service";
import {
  StartupService
} from "./services/startup/startup.service";
import {
  TemporalService
} from "./services/temporal/temporal.service";
import {
  TooltipService
} from "./services/tooltip/tooltip.service";
import { formattedTimeString } from "./util/utils";
import { FroalaEditorModule, FroalaViewModule } from "angular-froala-wysiwyg";
import { SupportService } from "./services/support/support.service";
import { ExporterService } from './services/exporter/exporter.service';

/*
 * Platform and Environment providers/directives/pipes
 */
// App is our top level component
// ng2-translate
// Application wide providers
const APP_PROVIDERS = [
  ...APP_RESOLVER_PROVIDERS,
  ...AUTH_GUARD_PROVIDERS,
  AppState,
  AppConfig,
  AuthenticationService,
  DataService,
  DashboardService,
  TooltipService,
  FormatterService,
  DataPreparationService,
  LocaleService,
  BrandService,
  TemporalService,
  StartupService,
  NotificationsService,
  SupportService,
  GlobalErrorHandler,
  ExporterService
];

/* tslint:disable interface-over-type-literal */
type StoreType = {
  state: InternalStateType,
  restoreInputValues: () => void,
  disposeOldHosts: () => void
};
/* tslint:enable interface-over-type-literal */

/* AoT - If you are using AoT compilation or Ionic 2, you must use an
* exported function instead of an inline function.
*/
export function customTranslateLoader(apollo: Apollo, logService: LoggingService) {
    return new CustomGraphQLTranslateLoader(apollo, logService);
}

export function startupServiceFactory(startupService: StartupService) {
    return () => startupService.load();
}

/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({
  declarations: [
    AppComponent,
    ErrorComponent
  ],
  /**
   * Import Angular's modules.
   */
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    HttpLinkModule,
    ApolloModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(ROUTES, {
      useHash: false,
      preloadingStrategy: PreloadAllModules
      // enableTracing: true
    }),
    StoreModule.forRoot(
      reducers
    ),
    LoggingModule.forRoot(),
    EffectsModule.forRoot(Effects),
    StoreDevtoolsModule.instrument({
      maxAge: 25 //  Retains last 25 states
    }),

    /**
     * @ngrx/router-store keeps router state up-to-date in the store.
     */
    StoreRouterConnectingModule.forRoot({
      /*
      They stateKey defines the name of the state used by the router-store reducer.
      This matches the key defined in the map of reducers
      */
      stateKey: "router"
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: ( customTranslateLoader ),
        deps: [Apollo, LoggingService]
      }
    }),
    DataTablesModule,
    BsDatepickerModule.forRoot(),
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot()
  ],
  /**
   * Expose our Services and Providers into Angular's dependency injection.
   */
  providers: [
    ENV_PROVIDERS,
    APP_PROVIDERS,
    {
      provide: MissingTranslationHandler,
      useClass: I18nMissingTranslationHandler
    },
    {
        provide: APP_INITIALIZER,
        useFactory: startupServiceFactory,
        deps: [StartupService],
        multi: true
    },

    {
      provide: META_REDUCERS,
      useFactory: getMetaReducers,
      multi: true
    }
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule {

  private _logger: Logger;

  constructor(
    public appRef: ApplicationRef,
    public appState: AppState,
    public loggerService: LoggingService,
    public appConfig: AppConfig
  ) {
    this._logger = new Logger("AppModule", loggerService);
    this._logger.info("Instantiated App Module" + formattedTimeString());
  }

  public hmrOnInit(store: StoreType) {
    if (!store || !store.state) {
      return;
    }
    console.log("HMR store", JSON.stringify(store, null, 2));
    /**
     * Set state
     */
    this.appState._state = store.state;
    /**
     * Set input values
     */
    if ("restoreInputValues" in store) {
      const restoreInputValues = store.restoreInputValues;
      setTimeout(restoreInputValues);
    }

    this.appRef.tick();
    delete store.state;
    delete store.restoreInputValues;
  }

  public hmrOnDestroy(store: StoreType) {
    const cmpLocation = this.appRef.components.map((cmp) => cmp.location.nativeElement);
    /**
     * Save state
     */
    const state = this.appState._state;
    store.state = state;
    /**
     * Recreate root elements
     */
    store.disposeOldHosts = createNewHosts(cmpLocation);
    /**
     * Save input values
     */
    store.restoreInputValues  = createInputTransfer();
    /**
     * Remove styles
     */
    removeNgStyles();
  }

  public hmrAfterDestroy(store: StoreType) {
    /**
     * Display new elements
     */
    store.disposeOldHosts();
    delete store.disposeOldHosts;
  }

}
