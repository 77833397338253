import {
  JsonConverter,
  JsonCustomConvert
} from "json2typescript";

import {
  TemporalAggregation
} from "../../model/business-dates/temporal-aggregation.enum";

@JsonConverter
export class TemporalAggregationConverter implements JsonCustomConvert<TemporalAggregation> {
  public serialize(data: TemporalAggregation): any {
    return data ? data.toString() : "";
  }

  public deserialize(val: any): TemporalAggregation {
    return TemporalAggregation[val] as TemporalAggregation;
  }
}

// tslint:disable-next-line:max-classes-per-file
@JsonConverter
export class TemporalAggregationArrayConverter implements JsonCustomConvert<TemporalAggregation[]> {

  public serialize(data: TemporalAggregation[]): any {
    if (!data) {
      return undefined;
    }
    return data.map((item) => item.toString());
  }

  public deserialize(val: any): TemporalAggregation[] {
    if (!val) {
      return [];
    }
    const ret = val.map((item: any) => TemporalAggregation[item]);
    return ret.filter((item: TemporalAggregation) => undefined !== item && null !== item);
  }
}
