import { Injectable } from "@angular/core";
import {
  Actions,
  Effect,
  ofType
} from "@ngrx/effects";
import * as fromRouterStore from "@ngrx/router-store";
import { Store } from "@ngrx/store";
import * as jp from "jsonpath";
import {
  switchMap,
  withLatestFrom
} from "rxjs/operators";

import { AppRoutes } from "../app.routes";
import {
  LayoutRoutes
} from "../layout/layout.routes";
import {
  BusinessDateRange
} from "../model/business-dates/business-date-range.model";
import {
  Event
} from "../model/events/event.model";
import {
  DashboardStateChangeDetail
} from "../services/dashboard/dashboard-state-change-detail.model";
import {
  DashboardService
} from "../services/dashboard/dashboard.service";
import {
  Logger
} from "../services/logging/logger";
import {
  LoggingService
} from "../services/logging/logging.service";
import * as GQL from "../util/graphql-tags";
import { json2ts } from "../util/json-2ts";
import {
  SLASH,
  URL_PARAM_START,
  LS_PREFIX
} from "../util/string-constants";
import { Context } from "./context";
import * as fromTemporal from "./temporal.reducers";
import { Noop } from "./effects";
import {
  BusinessDateRangeChanged, BusinessDateRangeAndTemporalAggregationChanged,
  SAVE_TEMPORAL_CONTEXT,
  SaveTemporalContext,
  RESTORE_TEMPORAL_CONTEXT
} from "./temporal.actions";
import {
  DASHBOARD_ACTIVATED,
  DASHBOARD_DEACTIVATED,
  DashboardActivated,
  DashboardDeactivated
} from "./ux.actions";
import { DateType, TemporalAggregation } from "../model/business-dates/temporal-aggregation.enum";
import { DashboardStateChangeEventDetail } from "../services/dashboard/dashboard-state-change-event-detail.model";

const LOG_NAMESPACE = "effects.ux";
const _DASHBOARD_URL_PATH = SLASH + AppRoutes.Application + SLASH + LayoutRoutes.Dashboard + SLASH;
const _DASHBOARD_URL_PATH_LENGTH = _DASHBOARD_URL_PATH.length;
const _SAVE_TEMPORAL_CONTEXT_LS_KEY =  LS_PREFIX + "temporal.saved";


interface SavedContext {
  range: BusinessDateRange;
  aggregation: TemporalAggregation;
  dashboard: string;
  dateType: DateType;
}

@Injectable()
export class UXEffects {

  private _logger: Logger;
  private _activeDashboard: string;
  private _lastDeActivatedDashboard: string;

  private async _handleStateChange(dashboardID: string, context: Context, jsonPathSuffix: string) {
    const spec = await this._dashboardService.dashboardSpecification(dashboardID);
    const scConf = jp.query(spec, `$.${GQL.ON_STATE_CHANGE}.${jsonPathSuffix}`);
    if (scConf.length === 0) {
      return new Noop();
    }
    const stateChangeDetail: DashboardStateChangeDetail = scConf[0];
    let newRange = BusinessDateRange.clone(context.temporal.range);
    let newAggregation: TemporalAggregation;
    let dateType: DateType;
    if (null != stateChangeDetail) {
      if (null != stateChangeDetail.Actions) {

        try {
          const actions = stateChangeDetail.Actions.map((action) => JSON.parse(action));
          actions.forEach((action) => {
            this._store$.dispatch(action);
          });
        } catch (err) {
          this._logger.error("Error in running Actions", err);
        }

        return;

      } else if (true === stateChangeDetail.clearEvents) {
        // The other side of the horrible temporary hack to make switching contexts work.
        // This will eventually be done by snapshots!
        this._restoreTemporalContextToLocalStorage(context);
        return;
      } else if (null != stateChangeDetail.ActivateEvent) {
        this._saveTemporalContextToLocalStorage(dashboardID, context);
        const activateEventDetails: DashboardStateChangeEventDetail = stateChangeDetail.ActivateEvent;
        const activateEvent: Event = Event.ToValidInputObject(activateEventDetails.Event);
        if (newRange[GQL.FROM]) {
          newRange[GQL.FROM][GQL.EVENT] = activateEvent;
          delete newRange[GQL.LAST];
        }
        if (newRange[GQL.TO]) {
          newRange[GQL.TO][GQL.EVENT] = activateEvent;
          delete newRange[GQL.NEXT];
        }
        newAggregation = activateEventDetails.aggregation || TemporalAggregation.Year;
        dateType = activateEventDetails.dateType || DateType.DEFAULT;
      }
      this._logger.warn("Have changed business date range to this: ", newRange);
      const _action = new BusinessDateRangeAndTemporalAggregationChanged(newRange, newAggregation, undefined, undefined, dateType);
      this._store$.dispatch(_action);
    }
  }

  private _getSavedTemporalContextFromLocalStorage(remove: boolean = false): SavedContext {
    const valueInLocalStorage = localStorage.getItem(_SAVE_TEMPORAL_CONTEXT_LS_KEY);
    const ret = valueInLocalStorage != null ? JSON.parse(valueInLocalStorage) as SavedContext : null;
    if (remove) {
      localStorage.removeItem(_SAVE_TEMPORAL_CONTEXT_LS_KEY);
    }
    return ret;
  }

  private dashboardIDFromURL(url: string): string {
    if (!url.startsWith(_DASHBOARD_URL_PATH)) {
      return null;
    }
    let ret = url.substring(_DASHBOARD_URL_PATH_LENGTH);
    const paramPos = ret.indexOf(URL_PARAM_START);
    if (paramPos >= 0) {
      ret = ret.substring(0, paramPos);
    }
    this._logger.debug(`Extracted dashboard ID ${ret} from supplied URL ${url} based on required URL start ${_DASHBOARD_URL_PATH}`);
    return ret;
  }

  private async _handleDeactivateDashboard() {
    if (null != this._activeDashboard) {
      this._logger.warn(`Asking for dashboard deactivation for active dashboard: ${this._activeDashboard}`);
      this._lastDeActivatedDashboard = this._activeDashboard;
      return new DashboardDeactivated(this._activeDashboard);
    } else {
      return new Noop();
    }
  }

  private async _handleDashboardActivation(dashboardID: string) {
    if (null != dashboardID) {
      return new DashboardActivated(dashboardID);
    }
  }


  @Effect({dispatch: true})
  public routerNavigation$ = this._action$
  .pipe(
    ofType(fromRouterStore.ROUTER_NAVIGATION),
    switchMap(async (action: fromRouterStore.RouterNavigationAction) => {
      const dashboardID = this.dashboardIDFromURL(action.payload.event.url);
      if (null == dashboardID) {
        return new Noop();
      }
      return this._handleDeactivateDashboard();
    })
  );


  @Effect({dispatch: true})
  public routerNavigated$ = this._action$
  .pipe(
    ofType(fromRouterStore.ROUTER_NAVIGATED),
    switchMap(async (action: fromRouterStore.RouterNavigationAction) => {
      const dashboardID = this.dashboardIDFromURL(action.payload.event.url);
      if (null == dashboardID) {
        return new Noop();
      }
      this._logger.warn(`Marking dashboard: ${dashboardID} as the active dashboard.`);
      this._activeDashboard = dashboardID;
      this._logger.warn(`Asking for dashboard activation for dashboard: ${dashboardID}`);
      return this._handleDashboardActivation(dashboardID);
    })
  );


  @Effect({dispatch: false})
  public dashboardActivated$ = this._action$
  .pipe(
    ofType(DASHBOARD_ACTIVATED),
    withLatestFrom(this._store$),
    switchMap(async ([action, context]) => {
      this._logger.warn("Activating Dashboard", action);
      const dashboardID = (action as DashboardActivated).payload;
      this._dashboardService.logDashboardNavigation(this._lastDeActivatedDashboard || "", dashboardID);
      return this._handleStateChange(dashboardID, context, GQL.ACTIVATE);
    })
  );

  @Effect({dispatch: false})
  public dashboardDeActivated$ = this._action$
  .pipe(
    ofType(DASHBOARD_DEACTIVATED),
    withLatestFrom(this._store$),
    switchMap(async ([action, context]) => {
      this._logger.warn("De-activating Dashboard", action);
      const dashboardID = (action as DashboardDeactivated).payload;
      return this._handleStateChange(dashboardID, context, GQL.DEACTIVATE);
    })
  );

  @Effect({dispatch: false})
  public saveTemporalContext$ = this._action$
  .pipe(
    ofType(SAVE_TEMPORAL_CONTEXT),
    withLatestFrom(this._store$),
    switchMap(async ([action, context]) => {
      this._logger.warn("Saving temporal context", action);
      const payload = (action as SaveTemporalContext);
      return this._saveTemporalContextToLocalStorage(payload.dashboardID, context);
    })
  );

  @Effect({dispatch: false})
  public restoreTemporalContext$ = this._action$
  .pipe(
    ofType(RESTORE_TEMPORAL_CONTEXT),
    withLatestFrom(this._store$),
    switchMap(async ([action, context]) => {
      this._logger.warn("Saving temporal context", action);
      const payload = (action as SaveTemporalContext);
      return this._restoreTemporalContextToLocalStorage(context);
    })
  );

  private _saveTemporalContextToLocalStorage(dashboardID: string, context: Context) {
    if (null == this._lastDeActivatedDashboard) {
      return;
    }
    // Total hack for now.  Storing the current temporal date range in local storage.
    // This will eventually be done using snapshots!
    const currentSaved = this._getSavedTemporalContextFromLocalStorage();
    if (null == currentSaved) {
      // If we are reloading a dashboard with a saved temporal context then we don't want to
      // save to local storage.  We only do that if it's empty or it wasn't saved from THIS dashboard.
      const toSave: SavedContext = {
        dashboard: dashboardID,
        range: context.temporal.range,
        aggregation: context.temporal.aggregation,
        dateType: context.temporal.dateType
      };
      localStorage.setItem(_SAVE_TEMPORAL_CONTEXT_LS_KEY, JSON.stringify(toSave));
    } else {
    }
  }

  private _restoreTemporalContextToLocalStorage(context: Context) {
    const storedContext = this._getSavedTemporalContextFromLocalStorage(true);
    if (null == storedContext) {
      return;
    }
    let newRange = BusinessDateRange.clone(context.temporal.range);
    newRange = storedContext.range;
    const newAggregation = storedContext.aggregation || context.temporal.aggregation;
    const dateType = storedContext.dateType || context.temporal.dateType;

    const _action = new BusinessDateRangeAndTemporalAggregationChanged(newRange, newAggregation, undefined, undefined, dateType);
    this._store$.dispatch(_action);
  }

  constructor(
    private _action$: Actions,
    private _store$: Store<Context>,
    loggingService: LoggingService,
    private _dashboardService: DashboardService) {
    this._logger = new Logger(LOG_NAMESPACE, loggingService);
  }

}
