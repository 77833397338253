import { JsonObject, JsonProperty, Any } from "json2typescript";
import * as GQL from "../../util/graphql-tags";

@JsonObject()
export class SupportServiceConfiguration {

  @JsonProperty(GQL.SCALAR_PROVIDER, String, false)
  public provider: string = null;

  @JsonProperty(GQL.SCALAR_CONFIG, Any, true)
  public config: any = {};

}
