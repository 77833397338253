import { ThresholdConfig } from "../../model/config/threshold-config.model";
import { ThresholdFormatterConfig } from "../../model/config/threshold-formatter-config.model";
import {
  Logger
} from "../logging/logger";
import { BaseSelector } from "./base-selector";
import { BrandService } from "./brand.service";
import {
  ISelectorProperties
} from "./selector.properties.interface";
import { json2ts } from "../../util/json-2ts";
import * as jp from "jsonpath";

export class ThresholdBasedSelector extends BaseSelector {

  thresholdFormatterConfig: ThresholdFormatterConfig;

  public constructor(_code: string, _config: any, _brandService: BrandService, _logger: Logger) {
    super(_code, _config, _brandService, _logger);

    this.thresholdFormatterConfig = json2ts(_config, ThresholdFormatterConfig);
    this.thresholdFormatterConfig.thresholds.forEach((threshold) => this._initializeItem(threshold));
  }

  public seriesColour(properties: ISelectorProperties): string {
    this._logger.info("Series colour of properties", {props: properties});
    // If there isn't a value in the properties then we
    // return the missing value colour.
    if (!properties || properties.value == null) {
      return this._seriesColour(this.thresholdFormatterConfig.default, properties);
    }

    let value: any = properties.value;
    const reverseSign = this.config["reverse-sign"];
    if (reverseSign && value !== 0) {
      value = value * -1;
    }

    let data = null == this.thresholdFormatterConfig.default ? value : this._seriesColour(this.thresholdFormatterConfig.default, properties);

    try {
      if (!(value instanceof Number)) {
        value = parseFloat(value);
      }
    } catch {
      return data;
    }

    if (isNaN(value)) {
      return data;
    }

    // OK, this is pretty simple.  Just loop round the thresholds and as soon
    // as we find one where properties.value >= threshold, return colour.
    // Works this simply because the thresholds are sorted descending.
    for (const curr of this.thresholdFormatterConfig.thresholds) {
      if (value < curr.threshold) {
        break;
      }
      data = this._seriesColour(curr, properties);
    }

    return data;
  }

  public styles(properties: ISelectorProperties): {[type: string]: any} {
    this._logger.info("Styles of properties", {props: properties});
    if (null == properties || null == properties.value) {
      if (this.thresholdFormatterConfig.nullDefault === false) {
        return null;
      }
      return null != this.thresholdFormatterConfig.default ? this.thresholdFormatterConfig.default.styles : null;
    }

    let value: any = properties.value;

    if (null != properties.jsonPath && null != properties.jsonData) {
      // Get value by running JSON path on properties.jsonData
      value = jp.query(properties.jsonData, properties.jsonPath)[0];
    } else {
      value = properties.value;
      if (typeof value === "string") {
        value = value.replace(/[!@#$%^&*£$,]/g, "");
      }
    }

    let data = null == this.thresholdFormatterConfig.default ? value : this.thresholdFormatterConfig.default[properties.type];
    try {
      if (!(value instanceof Number)) {
        value = parseFloat(value);
      }
    } catch {
      return data;
    }

    if (isNaN(value)) {
      return data;
    }
    for (const curr of this.thresholdFormatterConfig.thresholds) {
      if (value < curr.threshold) {
        break;
      }
      data = curr[properties.type];
    }
    return data;
  }

  protected _initializeItem(itemConfig: ThresholdConfig): ThresholdConfig {
    if (itemConfig.selectorID != null) {
      try {
        itemConfig.selector = this._brandService.seriesColourSelector(itemConfig.selectorID);
      } catch (err) {
        // tslint:disable-next-line:max-line-length
        throw new TypeError(`Selector '${this.code}' references selectorID '${itemConfig.selectorID}' which could not be retrieved due to the following error: ${err}`);
      }
    } else if (itemConfig.colour == null) {
      // tslint:disable-next-line:max-line-length
      throw new TypeError(`Selector '${this.code}' contains configuration which is missing both a .colour AND a .selectorID property.  Remove the item or specify one of these configuration properties.`);
    }
    return itemConfig;
  }

  protected _seriesColour(item: ThresholdConfig, properties: ISelectorProperties): string {
    if (item.selector != null) {
      return item.selector.seriesColour(properties);
    } else {
      return item.colour;
    }
  }
}
