import {
  Any,
  JsonObject,
  JsonProperty
} from "json2typescript";


@JsonObject
export class ThresholdConfig {

  @JsonProperty("colour", String, true)
  public colour: string = undefined;

  @JsonProperty("styles", Any, true)
  public styles: any = undefined;

  @JsonProperty("row-styles", Any, true)
  public rowStyles: any = undefined;

  @JsonProperty("selected-row-styles", Any, true)
  public selectedRowStyles: any = undefined;

  @JsonProperty("selectorID", Any, true)
  public selectorID: any = undefined;

  @JsonProperty("selector", Any, true)
  public selector: any = undefined;

  @JsonProperty("value", String, true)
  public value: string = undefined;

  @JsonProperty("threshold", Number, true)
  public threshold: number = undefined;

  @JsonProperty("thresholdExpression", String, true)
  public thresholdExpression: string = undefined;

}

