import { JsonConverter, JsonCustomConvert } from 'json2typescript';
import { MutationType } from './mutation-type.enum';

@JsonConverter
export class MutationTypeArrayConverter implements JsonCustomConvert<MutationType[]> {

  public serialize(data: MutationType[]): any {
    if (!data) {
      return undefined;
    }
    return data.map((item) => item.toString());
  }

  public deserialize(val: any): MutationType[] {
    if (!val) {
      return [];
    }
    const ret = val.map((item: any) => MutationType[item]);
    return ret.filter((item: MutationType) => undefined !== item && null !== item);
  }
}
