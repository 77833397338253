import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ddc-error',
  styleUrls: [ './error.style.scss' ],
  templateUrl: './error.template.html',
})
export class ErrorComponent {
  private router: Router;

  constructor(router: Router) {
    this.router = router;
  }

  public searchResult(): void {
    this.router.navigate(['/app', 'dashboard']);
  }
}
