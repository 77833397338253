import {
  ISelectorProperties
} from "./selector.properties.interface";
import { BaseSelector } from "./base-selector";

export class DynamicColourSelector extends BaseSelector {

  public seriesColour(properties: ISelectorProperties): string {
    const code = properties.name ? properties.name : properties.series;
    return code ? this.colorCodeByString(code) : null;
  }

  /**
   * Generates Hex code for any given string
   * @param val any string
   */
  private colorCodeByString(val: string): string {
    if (!val) {
      return null;
    }
    let hash = 0;
    for (let i = 0; i < val.length; i++) {
      // tslint:disable-next-line:no-bitwise
      hash = val.charCodeAt(i) + ((hash << 5) - hash);
    }
    let colour = '#';
    for (let i = 0; i < 3; i++) {
      // tslint:disable-next-line:no-bitwise
      const value = (hash >> (i * 8)) & 0xFF;
      colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
  }
}
