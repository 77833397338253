import * as d3 from "d3";
import * as jp from "jsonpath";

import { IExtent } from "./i-extent.interface";
import { Logger } from '../logging/logger';

const DATA_PATH: string = "data-path";
const FORCE_MIN_VALUE: string = "force-min-value";
const FORCE_MAX_VALUE: string = "force-max-value";
const DEFAULT_DATA_PATH: string = "$.values[*].y";

export class DefaultExtentFormatter implements IExtent {

  private _valuesDataPath: string;
  private _forceMinValue: number;
  private _forceMaxValue: number;

  constructor(config: any, private _logger: Logger) {
    this._valuesDataPath = config && config[DATA_PATH] !== undefined ? config[DATA_PATH] : DEFAULT_DATA_PATH;
    this._forceMinValue = config && config[FORCE_MIN_VALUE] !== undefined ? config[FORCE_MIN_VALUE] : Number.MAX_VALUE;
    this._forceMaxValue = config && config[FORCE_MAX_VALUE] !== undefined ? config[FORCE_MAX_VALUE] : -Number.MAX_VALUE;
  }

  public getExtent(self: IExtent, seriesData: any): number[] {
    let values: number[] = [];
    seriesData.forEach(function(ele: object) {
      const jpResults = jp.query(ele, (self as DefaultExtentFormatter)._valuesDataPath);
      values = values.concat(jpResults);
    });
    const ret = d3.extent(values);
    ret[0] = Math.min(this._forceMinValue, ret[0]);
    ret[1] = Math.max(this._forceMaxValue, ret[1]);
    return ret;
  }
}
