import { ITooltip } from "./i-tooltip.interface";
import { FormatterService } from "../formatter/formatter.service";
import { BrandService } from "../brand/brand.service";
import { TranslateService } from "@ngx-translate/core";
import { Logger } from "../logging/logger";

export class TooltipBase implements ITooltip {
  public formatters: any = {};
  constructor(
    protected _config: { [type: string]: any },
    protected _formatterService: FormatterService,
    protected _brandService: BrandService,
    protected _translateService: TranslateService,
    protected _logService: Logger
  ) {}

  public getLeafletStyles(): { [type: string]: any } {
    throw new Error("Not implemented in base class!");
  }

  public getMapToolTipProperties(): { [type: string]: any } {
    throw new Error("Not implemented in base class!");
  }

  public generateTooltipContent(data: any, params?: any): any {
    throw new Error("Not implemented in base class!");
  }

  public _isNumeric(value: number) {
    return value != null && !isNaN(value);
  }

  protected _getCalculatedVariance(
    value1: number,
    value2: number,
    label: string
  ) {
    const variance = {};
    variance["shortLabel"] = label;
    variance["formatPercent"] = true;
    if (value1 === null || value2 === 0 || value2 == null) {
      variance["value"] = null;
    } else {
      variance["value"] = 100 * ((value1 - value2) / value2);
    }
    return variance;
  }

  protected _initializeFormatters(params: any): void {
    if (params.formatters) {
      params.formatters.forEach((formatterName: string) => {
        const formatter = this._formatterService.formatter(formatterName);
        if (null == this.formatters[formatterName]) {
          this.formatters[formatterName] = formatter;
        }
      });
    }
  }
}
