import { SPACE } from "../util/string-constants";
import { md5 } from "../util/utils";
import { Person } from "./person.model";
import { User } from "./user/user.model";

export function avatarURL(hashKey: string, displayName: string, initials?: string): string {
  const hash = md5(hashKey);
  if (null == initials) {
    const names = displayName.split(SPACE);
    initials = names[0][0];
    if (names.length > 1) {
      initials += names[names.length-1][0];
    }
    initials = initials.toLowerCase();
  } else {
    initials = initials.substring(0, Math.min(initials.length-1, 1));
  }
  return `https://ui-avatars.com/api/?background=aaaaaa&color=fff&bold=true&rounded=false&name=${initials.toString()}&size=42&format=png`;
}
