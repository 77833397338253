import { Injectable } from "@angular/core";
import {
  Actions,
  Effect,
  ofType
} from "@ngrx/effects";
import { switchMap } from "rxjs/operators";

import {
  DataService
} from "../services/data/data.service";
import { Noop } from "./effects";
import {
  MutationSuccessful,
  UPDATE_TAG_VALUES,
  UpdateTagValues
} from "./mutation.actions";
import { MutationType } from "../dashboard/components/config/mutation-type.enum";
import { Timestamp } from "../model/timestamp.model";

const _LOG_ID = "comment.effects";

// tslint:disable-next-line:max-line-length
const _UPDATE_TAG_VALUES_GQL = `mutation updateTagValues($modifiedByID: String!, $timestamp:DateTimeInput!, $tagValuesData: String!) {
  UpdateTagValues(ModifiedBy: {id: $modifiedByID}, Timestamp: $timestamp, tagValuesData: $tagValuesData)
}
`;

@Injectable()
export class TagEffects {

  @Effect()
  public tagValuesUpdated$ = this._action$
  .pipe(
    ofType(UPDATE_TAG_VALUES),
    switchMap(async (action: UpdateTagValues) => {
      return this.updateTagValues(action);
    })
  );

  constructor(private _action$: Actions, private _dataService: DataService) {}

  private async updateTagValues(action: UpdateTagValues): Promise<MutationSuccessful | Noop> {
    const tagUpdates = action.payload;

    // console.log("TAG UPDATES", tagUpdates);

    const tagValuesData = {
      context: {
        reference: tagUpdates.reference,
        temporal: tagUpdates.temporal
      },
      tag: {
        id: tagUpdates.tag.id,
        "value-id": tagUpdates.tagValue.id
      }
    };

    if (null != tagUpdates.dimensionPayload) {
      (tagValuesData.context as any).dimension = tagUpdates.dimensionPayload;
    }

    // console.log("tagValuesData", tagValuesData);
    const params = {
      modifiedByID: tagUpdates.ModifiedBy.id,
      timestamp: Timestamp.ToValidInputObject(tagUpdates.Timestamp),
      tagValuesData: JSON.stringify(tagValuesData)
    };

    try {
      await this._dataService.mutate(_LOG_ID, _UPDATE_TAG_VALUES_GQL, params);
      console.log("TAGS UPDATED");

      return new MutationSuccessful([MutationType.Tag]);
    } catch (err) {
      console.log(err);
      console.log(`TAGS __NOT__ UPDATED!: ${err}`);
      return new Noop();
    }
  }

}
