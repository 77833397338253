import { Injectable } from "@angular/core";
import {
  Actions,
  Effect,
  ofType
} from "@ngrx/effects";
import { switchMap } from "rxjs/operators";
import { AUTHENTICATION_COMPLETED, AuthenticationCompleted } from "./user.actions";
import { SupportService } from "../services/support/support.service";

@Injectable()
export class ZendeskEffects {

  @Effect({ dispatch: false })
  public navigate$ = this._actions$
  .pipe(
    ofType(AUTHENTICATION_COMPLETED),
    switchMap(async (action: AuthenticationCompleted) => {
      this._supportService.onAuthenticationComplete(action.payload);
    })
  );

  constructor(
    private _actions$: Actions,
    private _supportService: SupportService
  ) {}
}
