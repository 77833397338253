import * as OU from '../../util/object-utils';
import { SPACE } from '../../util/string-constants';
import { ISelectorProperties } from '../brand/selector.properties.interface';
import { IFormatter } from '../formatter/i-formatter.interface';
import { TooltipBase } from './base-tooltip';
import * as jp from 'jsonpath';
import { translateUsingSuffix } from "../../util/translation-helpers";

const LABELS = "group-labels";
const COLON = ":";
const SEMI_COLON = ";";
const SEMI_COLON_SPACE = SEMI_COLON + SPACE;
const BRAND_SELECTOR = "brand-selector";
const TOOLTIP_HIGHLIGHTED_VALUE_KEY = "tooltip-highlighted-value-key";
const TOOLTIP_VALUE_KEY = "tooltip-value-key";
const TOOLTIP_BRAND_SELECTOR = "tooltip-brand-selector";
const TOOLTIP_POPUP_TITLE_KEY = "tooltip-popup-title-key";
const TOOLTIP_POPUP_SUBTITLE_KEY = "tooltip-popup-subtitle-key";
const SHOW_ZERO_VALUES = "show-zero-values";
const TOOLTIP_POPUP_COLUMN_HEADING_KEY = "tooltip-popup-column-heading-key";
const TOOLTIP_POPUP_COLUMN_TITLE_KEY = "tooltip-popup-column-title-key";
const TOOLTIP_POPUP_ROW_TITLE_KEY = "tooltip-popup-row-title-key";
const TOOLTIP_POPUP_TOTAL_TITLE_KEY = "tooltip-popup-total-title-key";

export class MultiDimensionVerticalBarTooltip extends TooltipBase {
  /*
  * Have to modify nvd3 code to tell us if the series isArea, on hover, so that
  * we can change the shape of bullet based on line type.
  */

  public generateTooltipContent(data: any, params?: any): string {
    this._logService.debug("Generating Dimension Horizontal Bar ToolTip of data ", {tooltipData: data, parameter: params});
    const valuePaths = params["value-paths"];
    const brandSelectorConfig = this._config[BRAND_SELECTOR];
    const tooltipStyleBrandSelector = brandSelectorConfig[TOOLTIP_BRAND_SELECTOR];
    const titleStyle = this._getStyleFromBrandSelector(tooltipStyleBrandSelector, brandSelectorConfig[TOOLTIP_POPUP_TITLE_KEY]);
    const subtitleStyle = this._getStyleFromBrandSelector(tooltipStyleBrandSelector, brandSelectorConfig[TOOLTIP_POPUP_SUBTITLE_KEY]);

    const columnHeaderStyle = this._getStyleFromBrandSelector(tooltipStyleBrandSelector, brandSelectorConfig[TOOLTIP_POPUP_COLUMN_HEADING_KEY]);
    const columnTitleStyle = this._getStyleFromBrandSelector(tooltipStyleBrandSelector, brandSelectorConfig[TOOLTIP_POPUP_COLUMN_TITLE_KEY]);
    const rowTitleStyle = this._getStyleFromBrandSelector(tooltipStyleBrandSelector, brandSelectorConfig[TOOLTIP_POPUP_ROW_TITLE_KEY]);

    const valueStyle = this._getStyleFromBrandSelector(tooltipStyleBrandSelector, brandSelectorConfig[TOOLTIP_VALUE_KEY]);
    const highlightedValueStyle = this._getStyleFromBrandSelector(tooltipStyleBrandSelector, brandSelectorConfig[TOOLTIP_HIGHLIGHTED_VALUE_KEY]);

    const totalTitleValueStyle = this._getStyleFromBrandSelector(tooltipStyleBrandSelector, brandSelectorConfig[TOOLTIP_POPUP_TOTAL_TITLE_KEY]);
    const seriesData = data.series[0];
    const showZeroValues = this._config[SHOW_ZERO_VALUES] === undefined ? true : this._config[SHOW_ZERO_VALUES];
    let translatedName: string = "";
    if (null == data.data) {
      data.data = data.point;
    }
    const seriesKeyName = data.data.leftLabel || data.data.name || data.data.key || data.chartSeries.key;
    const translatedSeriesKeyName = translateUsingSuffix(seriesKeyName, params.dimensionNameTranslationSuffix, this._translateService);
    const metaDataArray = data.data.meta;
    let metaData: any;
    if (params.showOtherValue) {
      metaData = metaDataArray.map((s: any) => Object.assign({}, s));
      metaData = metaData.reverse();
    } else {
      metaData = metaDataArray;
    }
    let dataFormatter: IFormatter;
    let percentWithBracketsFormatter: IFormatter;
    let percentDefaultFormatter: IFormatter;
    let salesLabel = "";
    let sharePctLabel = "";
    let variancePctLabel = "";
    let VSLabel = "";
    let titleSuffix = " Breakdown";
    let formatters: any = {};
    if (params) {
      if (params.formatter) {
        dataFormatter = this._formatterService.formatter(params.formatter);
      }
      if (params.formatters) {
        params.formatters.forEach((formatterName: string) => {
          const formatter = this._formatterService.formatter(formatterName);
          if (null == formatters[formatterName]) {
            formatters[formatterName] = formatter;
          }
        });
      }
      if (params.percentFormatter) {
        percentWithBracketsFormatter = this._formatterService.formatter(params.percentFormatter);
      }
      if (params.percentDefault) {
        percentDefaultFormatter = this._formatterService.formatter(params.percentDefault);
      }
      if (params.titleTranslationKey) {
        translatedName = this._translateService.instant(params.titleTranslationKey);
      } else if (null != this._config[LABELS]) {
        translatedName = this._translateService.instant(this._config[LABELS][data.index]);
      }
      if (params.salesLabel) {
        salesLabel = this._translateService.instant(params.salesLabel);
      }
      if (params.shareLabel) {
        sharePctLabel = this._translateService.instant(params.shareLabel);
      }
      if (params.variancePctLabel) {
        variancePctLabel = this._translateService.instant(params.variancePctLabel);
      }
      if (params.vsLabel) {
        VSLabel = this._translateService.instant(params.vsLabel);
      }
      if (null != params.titleSuffix) {
        titleSuffix = params.titleSuffix;
      }
    }
    let popupHTML = "";
    popupHTML += `
      <table style="width: 95%; max-width: 420px; font-variant: small-caps;">
        <thead>
          <tr style="${titleStyle}">
            <td colspan="6">${translatedName}${titleSuffix}</strong></td>
          </tr>`;
    if (metaDataArray.length > 1 && (data.data.other === undefined || data.data.other)) {
      popupHTML += `<tr style="${subtitleStyle}"><td colspan="6"> ` + seriesKeyName + `</strong></td></tr>`;
    }
    popupHTML += '</thead><tbody>';
    let titleLabels: any[] = [];
    let shortLabels: any[] = [];
    if (null != valuePaths) {
      valuePaths.forEach((valuePath: any) => {
        const value = jp.query(metaData[0], valuePath)[0];
        titleLabels.push(value.label);
        shortLabels.push(value.shortLabel);
      });
    } else {
      titleLabels = metaData[0].values.map((labelValue: any) => labelValue.label);
      shortLabels = metaData[0].values.map((labelValue: any) => labelValue.shortLabel);
    }
    const additionalMeta = metaData[0].additionalMeta.map((labelValue: any) => labelValue.label);
    const currentItemShortLabel = data.data.shortLabel;
    const currentItemIndex = data.data.valueIndex;
    if (metaDataArray.length > 1) {
      popupHTML += '<tr class="store-popup-text">';
      popupHTML += `<td colspan="2" style="${rowTitleStyle}"></td>`;
      for (const title of titleLabels) {
        popupHTML += `<td colspan="2" style="${columnTitleStyle}">` + title + '</td>';
      }
      if (additionalMeta.length > 0 || params.addVariancePct) {
        popupHTML += `<td style="${columnHeaderStyle}">vs ${shortLabels.slice(-1)[0]}</td>`;
      }
      popupHTML += `</tr>`;

      popupHTML += '<tr class="store-popup-text">';
      popupHTML += `<td colspan="2" style="${rowTitleStyle}"></td>`;
      metaData[0].values.forEach(() => {
        popupHTML += `<td style="${columnHeaderStyle}">${salesLabel}</td>`;
        popupHTML += `<td style="${columnHeaderStyle}">${sharePctLabel}</td>`;
      });
      if (params.addVariancePct) {
        popupHTML += `<td style="${columnHeaderStyle}">${variancePctLabel}</td>`;
      }
      for (const adm of additionalMeta) {
        popupHTML += `<td style="${columnHeaderStyle}">${adm}</td>`;
      }
      popupHTML += '</tr>';

      const currentItemKey = data.data.key;
      metaData.forEach((meta: any, index: number) => {
        const metaValues = meta.values.map((x: any) => Object.assign({}, x));
        let dimensionName = meta.name;
        let hoveredStyle = "";
        const onlyValues = metaValues.map((labelValue: any) => labelValue.value);
        const totalValue = onlyValues.reduce((a: any, b: any) => a + b, 0);
        if (!showZeroValues && (totalValue < 0.01 || totalValue === null)) {
          return;
        }
        if (params.addVariancePct) {
          const variance = this._getCalculatedVariance(onlyValues[0], onlyValues[1], variancePctLabel);
          metaValues.push(variance);
        }

        if (index === metaData.length - 1 && params.showOtherValue  && (data.data.other === undefined || data.data.other)) {
          dimensionName = "Other " + dimensionName;
        }

        if (meta.key === seriesData.key || (seriesData.key === "All" && meta.name === data.data.name)) {
          hoveredStyle = "border-bottom-style: solid; border-top-style: solid; border-width:1px; border-color:" + meta.color + ';';
        }
        popupHTML += '<tr class="store-popup-text" style="' + hoveredStyle + '"><td align="right" class="legend-icon legend-color-guide-area">';
        popupHTML += '<div style="background-color: ' + meta.color;
        popupHTML += '; margin-top:2px"></div></td><td nowrap="wrap" width="80px" style="color:#555555; text-align: left;" class="key">';
        popupHTML += dimensionName + '</td>';
        metaValues.forEach((md: any, i: number) => {
          const isValueToHighlight = (currentItemKey === meta.key || (seriesData.key === "All" && meta.name === data.data.name)) &&
                                     (currentItemShortLabel === md.shortLabel);
          const valueStyling =
            isValueToHighlight
              ? highlightedValueStyle
              : valueStyle;
          if (md.formatPercent === undefined || !md.formatPercent) {
            let formattedPercentage;
            if (null == data.data.totalValues[i]) {
              formattedPercentage = "NA";
            } else {
              const percentageShare = (md.value / data.data.totalValues[i]) * 100;
              formattedPercentage = percentWithBracketsFormatter.formatData(percentageShare);
            }

            const dimensionValue = dataFormatter ? dataFormatter.formatData(md.value) : md.value;
            popupHTML += `<td style="${valueStyling}">${dimensionValue}</td>`;
            popupHTML += `<td style="${valueStyling}">${formattedPercentage}</td>`;
          } else {
            const dimensionValue = percentDefaultFormatter ? percentDefaultFormatter.formatData(md.value) : md.value;
            popupHTML += `<td style="${valueStyling}">${dimensionValue}</td>`;
          }
        });
        // if (!data.data.pivot) {
        meta.additionalMeta.forEach((aditionalMetaData: any, admIndex: number) => {
          const formattedAddtionalValue = percentDefaultFormatter.formatData(aditionalMetaData.value);

          popupHTML += `<td style="${valueStyle}">${formattedAddtionalValue}</td>`;
        });
        popupHTML += '</tr>';
        // }
      });
      popupHTML += `<tr><td colspan="2" style="${totalTitleValueStyle}">${translatedName} Total</td>`;
      shortLabels.forEach((md: any, i: number) => {
        const dimensionTotalValue = dataFormatter ? dataFormatter.formatData(data.data.totalValues[i]) : data.data.totalValues[i];
        popupHTML += `<td style="${valueStyle}">${dimensionTotalValue}</td>`;
        popupHTML += `<td style="${valueStyle}">${percentDefaultFormatter ? percentDefaultFormatter.formatData(100) : 100}</td>`;
      });
      popupHTML += '</tr>';
    } else {
        popupHTML += '<tr class="store-popup-text">';
        popupHTML += `<td colspan="2" style="${rowTitleStyle}"></td>`;
        for (const title of titleLabels) {
          popupHTML += `<td style="${columnTitleStyle}">` + title + '</td>';
        }
        if (params.addVariancePct) {
          popupHTML += `<td style="${columnHeaderStyle}">${VSLabel} ${shortLabels.slice(-1)[0]}</td>`;
        }
        if (null == valuePaths) {
          for (const adm of additionalMeta) {
            popupHTML += `<td style="${columnHeaderStyle}">${adm}</td>`;
          }
        }
        popupHTML += `</tr>`;
        // const dimensionValue = dataFormatter ? dataFormatter.formatData(data.data.totalValue) : data.data.totalValue;
        popupHTML += '<tr><td align="right" class="legend-icon legend-color-guide-area"><div style="background-color: ';
        popupHTML += data.data.color + '; margin-top:2px">';
        popupHTML += `</div></td><td>${translatedSeriesKeyName}</td>`;
        if (metaData[0]) {
          let metaValues;
          if (null != valuePaths) {
            metaValues = valuePaths.map((valuePath: any) => {
              return jp.query(metaData[0], valuePath)[0];
            });
          } else {
            metaValues = metaData[0].values.map((x: any) => Object.assign({}, x));
          }
          if (params.addVariancePct) {
            const mv = this._getCalculatedVariance(metaValues[0].value, metaValues[1].value, variancePctLabel);
            metaValues.push(mv);
          }
          metaValues.forEach((md: any, i: number) => {
            if (null != params.percentValueIndexes) {
              md.formatPercent = params.percentValueIndexes.indexOf(i) !== -1 ? true : false;
            }
            const isValueToHighlight =  null != currentItemIndex ? currentItemIndex === i : (currentItemShortLabel === md.shortLabel);
            const valueStyling =
              isValueToHighlight
                ? highlightedValueStyle
                : valueStyle;
            if (!md.formatPercent) {
              let currentFormatter = dataFormatter;
              if (params.formatters) {
                currentFormatter = formatters[params.formatters[i]];
              }
              const dimensionValue = currentFormatter ?
                                        md.value !== null ?
                                          currentFormatter.formatData(md.value) :
                                        "NA" :
                                      md.value;
              popupHTML += `<td style="${valueStyling}">${dimensionValue}</td>`;
            } else {
              const dimensionValue = percentDefaultFormatter ?
                                      md.value !== null ?
                                        percentDefaultFormatter.formatData(md.value) :
                                          "NA" :
                                      md.value;
              popupHTML += `<td style="${valueStyling}">${dimensionValue}</td>`;
            }
          });
          if (null == valuePaths) {
            metaData[0].additionalMeta.forEach((aditionalMetaData: any, admIndex: number) => {
              const formattedAddtionalValue = percentDefaultFormatter.formatData(aditionalMetaData.value);
              popupHTML += `<td style="${valueStyle}">${formattedAddtionalValue}</td>`;
            });
          }
        } else {
          const dimensionValue = dataFormatter ? dataFormatter.formatData(data.data.totalValue) : data.data.totalValue;
          popupHTML += `<td style="${valueStyle}">${dimensionValue}</td>`;
        }
        popupHTML += `</tr>`;
    }
    popupHTML += '</tbody></table>';
    return popupHTML;
  }

  private _getStyleFromBrandSelector(brandSelector: string, key: string): string {
    const bStyle = this._brandService.styleSelector(brandSelector);
    const props: ISelectorProperties = {id: key};
    if (bStyle.styles(props)) {
      return `${OU.object2String(bStyle.styles(props), COLON, SEMI_COLON_SPACE)};`;
    }
    return "";
  }

}
