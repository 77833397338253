import { Action } from "@ngrx/store";

import { ReferenceContext } from './reference.reducers';

export interface IDimension {
    level: number;
    ids: string[];
}

export interface IDimensionPayload {
    level: number;
    id: string;
    type: string;
}

/* tslint:disable max-classes-per-file */
export const DIMENSION_AGGREGATIONS_DEMO_RESET = 'DIMENSION_AGGREGATIONS_DEMO_RESET';
export const DIMENSION_AGGREGATIONS_CHANGED = 'DIMENSION_AGGREGATIONS_CHANGED';
export const UPDATE_DIMENSIONS = 'UPDATE_DIMENSIONS';

// Payload Action Type - Dispatch event is still DIMENSION_AGGREGATIONS_CHANGED, but we need type key in payload that
// Tells us what action to be performed on selection and deselection of bar.
export const ADD = "ADD"; // Append the selection to Array
export const REMOVE = "REMOVE"; // Remove the selection from Array
export const REPLACE = "REPLACE"; // Replace existing value with new one
export const CLEAR = "CLEAR"; // Replace existing value with new one

export const IDs = "ids";

export class DimensionAggregationsDemoReset implements Action {
  public readonly type = DIMENSION_AGGREGATIONS_DEMO_RESET;
  constructor(public context: ReferenceContext) {}
}

export class DimensionAggregationsUpdated implements Action {
  public readonly type = DIMENSION_AGGREGATIONS_CHANGED;
  constructor(public payload: IDimensionPayload) {}
}

export class UpdateDimensions implements Action {
  public readonly type = UPDATE_DIMENSIONS;
  constructor(public payload: any) {}
}

export type DimensionActions = DimensionAggregationsUpdated | DimensionAggregationsDemoReset | UpdateDimensions;
/* tslint:enable max-classes-per-file */
