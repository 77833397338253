import {
  TranslateService
} from "@ngx-translate/core";
import d3 from "d3";
import * as jp from "jsonpath";
import { Logger } from "../logging/logger";
import { BaseFormatter } from "./base-formatter";
import {
  FormatterService
} from "./formatter.service";

export class DateFormatter extends BaseFormatter {

  constructor(locale: d3.Locale, config: any, translateService: TranslateService, formatterService: FormatterService, logger: Logger) {
    super(locale, config, translateService, formatterService, logger);
  }

  public formatData(datum: any, _params?: any): string {
    if (this.config.path) {
      datum = jp.query(datum, this.config.path);
    }
    const formatTime = this.locale.timeFormat(this.config.formatString);
    const ret = formatTime(new Date(datum));
    return this.translatePlaceholders(ret);
  }
}
