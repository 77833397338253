import {
  JsonObject,
  JsonProperty,
  Any
} from "json2typescript";

import * as constants from "../../../util/string-constants";
import { SeriesValues } from "./series-values";

@JsonObject
export class Series {

    public static SeriesDefinitions(seriesConfig: any, ignoreCoalesce: boolean= false): Array<{[key: string]: any}> {
      const seriesQueryDefs: Array<{[key: string]: any}> = [];
      const seriesDef: {[type: string]: any} = {};
      if (seriesConfig.series !== undefined) {
        seriesConfig.series.forEach((seriesEle: object) => {
          // tslint:disable-next-line:max-line-length
          const bmk = seriesEle[constants.BENCHMARK] != null ? seriesEle[constants.BENCHMARK] : false;
          
          const seriesAgg = seriesEle[constants.AGGREGATION];
          const customSerAggID = seriesAgg != null && seriesAgg[constants.CUSTOM_ID] != null ? `,customID: ${seriesAgg[constants.CUSTOM_ID]}`:'';
          const aggregation =  seriesAgg != null ? `, Aggregation: {Type: ${seriesAgg['Type']}${customSerAggID}}`: '';
          
          let coalesce = '';
          if (seriesEle[constants.COALESCE] != null && !ignoreCoalesce) {
            const coalesceSeriesConfig = {
              series: seriesEle[constants.COALESCE]
            };
            const coalescedSeriesElements = this.SeriesDefinitions(coalesceSeriesConfig, true);
            coalesce  = `, Coalesce:[${coalescedSeriesElements[0].series}]`;
          }
        
          let seriesDefinition = `{id: "${seriesEle[constants.ID]}", benchmark: ${bmk}${aggregation}${coalesce}}`;
          
          if (seriesConfig.seriesGroups != null) {
            const group: string = seriesEle[constants.GROUP];
            seriesDef[group] = seriesDef[group] ? seriesDef[group] + constants.COMMA_SPACE + seriesDefinition : seriesDefinition;
          } else {
            // tslint:disable-next-line:max-line-length
            seriesDef[constants.DEFAULT_SERIES_GROUP] = seriesDef[constants.DEFAULT_SERIES_GROUP] ? seriesDef[constants.DEFAULT_SERIES_GROUP] + constants.COMMA_SPACE + seriesDefinition : seriesDefinition;
          }
        });
        if (seriesConfig.seriesGroups) {
          for (const key of Object.keys(seriesConfig.seriesGroups)) {
            seriesQueryDefs.push(
              {
                regex: new RegExp(seriesConfig.seriesGroups[key][constants.SERIES_DEFINITION_REGEX], constants.REGEX_GLOBAL_MATCH),
                series: seriesDef[key]
              }
            );
          }
        } else {
          seriesQueryDefs.push(
            {
              regex: new RegExp(constants.SERIES_DEFINITION_PLACEHOLDER, constants.REGEX_GLOBAL_MATCH),
              series: seriesDef[constants.DEFAULT_SERIES_GROUP]
            }
          );
        }
      }
      return seriesQueryDefs;
    }

    @JsonProperty("id", String, false)
    public id: string = undefined;

    @JsonProperty("axis", Number, true)
    public axis: number = undefined;

    @JsonProperty("reference-threshold-series-index", Number, true)
    public referenceThresholdSeriesIndex: number = undefined;

    @JsonProperty("display-axis-if-no-data", Boolean, true)
    public displayAxisIfNoData: boolean = true;

    @JsonProperty("values", [SeriesValues], true)
    public values: SeriesValues[] = undefined;

    @JsonProperty("color", String, true)
    public color: string = undefined;

    @JsonProperty("type", String, true)
    public type: string = undefined;

    @JsonProperty("benchmark", Boolean, true)
    public benchmark: boolean = false;

    // This should be CSS class that will be applied on Series
    @JsonProperty("cssClass", String, true)
    public cssClass: string = undefined;

    @JsonProperty("series-key", Number, true)
    public seriesKey: number = undefined;

    @JsonProperty("tooltip-css-class", String, true)
    public tooltipCSSClass: string = undefined;

    // This should be set to NAME, TINYNAME or LONGNAME
    @JsonProperty("name", String, false)
    public name: string = "NAME";

    @JsonProperty("group", String, true)
    public group: string = undefined;

    @JsonProperty("parent-series-id", String, true)
    public parentSeriesID: string = undefined;

    @JsonProperty("prior-period", Boolean, true)
    public priorPeriod: boolean = false;

    // If we don't want to take the default name that is given for series,
    // We can override it by this config key, by providing the full path of the alternative name
    @JsonProperty("series-name-path", String, true)
    public seriesNamePath: string = undefined;

    public translatedName: string;

    public translatedNameWithAxisHint: string;

    // Adding _seriesID to uniquly identify series
    // Id will remain same for Sales and Sales Bmk
    @JsonProperty("series-id", String, true)
    public seriesID: string = undefined;

    public get referenceSeriesID(): string {
      if (undefined === this.seriesID) {
        return this.id;
      }
      return this.seriesID;
    }
    
    @JsonProperty("Aggregation", Any, true)
    public Aggregation: any = undefined;

    @JsonProperty("Coalesce", Any, true)
    public Coalesce: any = undefined;

    /*
    * Since series id is optional, we can use this getter
    * function to get the sid if given else its always the
    * combination of series id and its benchmark type.
    */
    public get IDWithBenchmarkSuffix(): string {
        if (this.seriesID !== undefined) {
            return this.seriesID;
        } else {
            return this.id + (this.benchmark ? "-bmk" : "");
        }
    }

}
