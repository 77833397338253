export enum NameTypeDefaults {
  Name = ".NAME",
  Singular = ".NAME",
  Long = ".LONGNAME",
  Tiny = ".TINYNAME",
  Plural = ".PLURAL",
  Icon = ".ICON",
  None = ".NONE",
  Blank = ""
}

export enum NameType {
  Name,
  Singular,
  Long,
  Tiny,
  Plural,
  Icon,
  None,
  Blank
}
