import { JwtHelperService } from "@auth0/angular-jwt";
import * as fromUser from '../../context/user.reducers';
import { UserContextKeys } from "../../context/context";
import { LS_USER } from '../../util/string-constants';

/**
 *  The data layer needs to be able to be called BEFORE the ngrx state has been initialised because when the callback comes from Auth0
 * that is a full page reload, so things get initialized all over again.
 * So, to work around this (and it's an acknowledged hack but it's pretty harmless)
 * we write DIRECTLY to local storage and the data layer reads DIRECTLY from local storage.
 * Hence it's encapsulated into these two helper functions.
 */
function storeUserContext(auth: fromUser.UserContext) {
  localStorage.setItem(LS_USER, JSON.stringify(auth));
}

function storeAuth(auth: fromUser.Auth) {
  const val = JSON.parse(localStorage.getItem(LS_USER) || "{}");
  val[UserContextKeys.AUTH] = auth;
  localStorage.setItem(LS_USER, JSON.stringify(val));
}

function getAuth(): fromUser.Auth {
  const userContext: any = JSON.parse(localStorage.getItem(LS_USER));
  return userContext ? userContext.auth : undefined;
}

function getJWT(auth: fromUser.Auth = null): string {
  let jwtToken: string;
  if (null == auth) {
    auth = getAuth();
  }
  if (auth) {
    jwtToken = auth.idToken;
  }
  return jwtToken;
}

export interface AuthenticationCheckResult {
  isTokenExpired: boolean;
  auth?: any;
}

function isAuthenticated(): AuthenticationCheckResult {
  const auth = getAuth();
  const token = getJWT(auth);
  const isExpired = isTokenExpired(token);
  let ret;
  if (isExpired) {
    ret = {isTokenExpired: true};
  } else {
    ret = {isTokenExpired: false, auth};
  }
  return ret;
}

const _JWT_HELPTER = new JwtHelperService();

function isTokenExpired(token: string) {
  if (undefined === token) {
    return true;
  }
  return _JWT_HELPTER.isTokenExpired(token);
}

export {
  isTokenExpired,
  isAuthenticated,
  storeUserContext,
  getAuth,
  storeAuth,
  getJWT
};
