import { ThresholdFormatterConfig } from "../../model/config/threshold-formatter-config.model";
import { ThresholdConfig } from "../../model/config/threshold-config.model";
import {
  Logger
} from "../logging/logger";
import { BrandService } from "./brand.service";
import {
  ISelectorProperties
} from "./selector.properties.interface";
import {
  ThresholdBasedSelector
} from "./threshold-based-selector";
import { json2ts } from "../../util/json-2ts";

export class ThresholdExpressionBasedSelector extends ThresholdBasedSelector {

  public constructor(_code: string, _config: any, _brandService: BrandService, _logger: Logger) {
    super(_code, _config, _brandService, _logger);

    const thresholds: [ThresholdConfig] = json2ts(this.config.thresholds, [ThresholdConfig]);
    thresholds.forEach((threshold) => this._initializeItem(threshold));
    // Sort the thresholds in DESCENDING order so we process the highest
    // threshold first.  That way we can stop the second we get to one we match.
    // If we sorted them ascending we'd have to keep going in case we found
    // a higher threshold value that also matched which is inefficient.
    this.thresholdFormatterConfig.thresholds = thresholds;
  }

  public seriesColour(properties: ISelectorProperties): string {
    this._logger.info("Series colour of properties", {props: properties});
    let curr: ThresholdConfig;
    // If there isn't a value in the properties then we
    // return the missing value colour.
    if (!properties || properties.values == null) {
      return this._seriesColour(this.thresholdFormatterConfig.default, properties);
    }

    const value: any = properties.values;

    // OK, this is pretty simple.  Just loop round the thresholds and as soon
    // as we find one where properties.value >= threshold, return colour.
    // Works this simply because the thresholds are sorted descending.
    for (curr of this.thresholdFormatterConfig.thresholds) {
      if (curr.thresholdExpression) {
        let thresholdExpressionWithValues = curr.thresholdExpression;
        for (let i = 0; thresholdExpressionWithValues.match(/{\d}/) ; i++) {
          thresholdExpressionWithValues = thresholdExpressionWithValues.replace(`{${i}}`, value[i]);
        }
        // tslint:disable-next-line:no-eval
        if (eval(thresholdExpressionWithValues) === true) {
          return this._seriesColour(curr, properties);
        }
      } else if (value >= curr.threshold) {
        return this._seriesColour(curr, properties);
      }
    }

    // Nothing matched, so use default colour.
    return this._seriesColour(this.thresholdFormatterConfig.default, properties);
  }

  public styles(properties: ISelectorProperties): {[type: string]: any} {
    this._logger.info("Styles of properties", {props: properties});
    let curr: ThresholdConfig;
    if (!properties || !properties.value) {
      return this.thresholdFormatterConfig.default.styles;
    }

    let value: any = properties.value;
    if (!(value instanceof Number)) {
      value = parseFloat(value);
    }

    if (isNaN(value)) {
      return this.config.default[properties.type];
    }

    for (curr of this.config.thresholds) {
      if (value >= curr.threshold) {
        return curr[properties.type];
      }
    }
    return this.config.default[properties.type];
  }
}
