import { Location } from "@angular/common";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
  Actions,
  Effect,
  ofType
} from "@ngrx/effects";
import { switchMap, tap } from "rxjs/operators";

import { AppRoutes } from "../app.routes";
import {
  LayoutRoutes
} from "../layout/layout.routes";
import {
  EMPTY,
  URL_SEP
} from "../util/string-constants";
import { Noop } from "./effects";
import * as RouterActions from "./router.actions";
import {
  ActiveRouteChanged
} from "./user.actions";



@Injectable()
export class RouterEffects {


  @Effect({ dispatch: true })
  public navigate$ = this._actions$
  .pipe(
    ofType(RouterActions.GO),
    switchMap(async (action: RouterActions.Go) => {
      const path = action.payload.path;
      const queryParams = action.payload.query;
      const options = action.payload.options;
      const success = await this._router.navigate(path, { queryParams, ...options });
      if (success) {
        return new ActiveRouteChanged(this._activeRouteFullPath(path));
      } else {
        return new Noop();
      }
    })
  );

  @Effect({dispatch: true})
  public changeDashboard$ = this._actions$
  .pipe(
    ofType(RouterActions.CHANGE_DASHBOARD),
    switchMap(async (action: RouterActions.ChangeDashboard) => {
      const routerAction = new RouterActions.Go(
        {
          path: [AppRoutes.Application, LayoutRoutes.Dashboard, action.payload.dashboard],
          options: { skipLocationChange: false }
        }
      );
      return routerAction;
    })
  );

  @Effect({ dispatch: false })
  public navigateBack$ = this._actions$
  .pipe(
    ofType(RouterActions.BACK),
    tap(() => this._location.back())
  );

  @Effect({ dispatch: false })
  public navigateForward$ = this._actions$
  .pipe(
    ofType(RouterActions.FORWARD),
    tap(() => this._location.forward())
  );

  constructor(
    private _actions$: Actions,
    private _router: Router,
    private _location: Location
  ) {}

  private _activeRouteFullPath(path: any[]): string {
    return path.reduce((assembledPath: string, currPathElement: string) => {
      let sep: string = URL_SEP;
      if (currPathElement.startsWith(URL_SEP)) {
        sep = EMPTY;
      }
      return assembledPath + sep + currPathElement;
    }, EMPTY);
  }
}
