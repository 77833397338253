/**
 * Angular bootstrapping
 */
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { decorateModuleRef } from "./app/environment";
/**
 * App Module
 * our top level module that holds all of our components.
 */
import { AppModuleNgFactory } from "./app/app.module.ngfactory";

platformBrowserDynamic()
  .bootstrapModuleFactory(AppModuleNgFactory)
  .then(decorateModuleRef)
  .catch((err) => console.error(err));
