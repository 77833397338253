import * as DemoActions from './demo.actions';

import { NavAlert } from '../layout/navbar/navAlert.model';
import * as OU from '../util/object-utils';

export interface DemoContext {
  dismissedAlerts: string[];
  priorityAlert: NavAlert;
  enabled: boolean;
}

const initialState: DemoContext = {
  dismissedAlerts: [],
  priorityAlert: null,
  enabled: false
};

export function demoReducer(state = initialState, action: DemoActions.DemoActions): DemoContext {
  switch (action.type) {
    case DemoActions.ALERT_DISMISSED:
      return {
        ...state,
        dismissedAlerts: action.payload
      };
    case DemoActions.ADD_PRIORITY_ALERT:
      return {
        ...state,
        priorityAlert: action.payload
      };
    case DemoActions.SET_DEMO_STATE:
      return {
        ...state,
        enabled: action.enabled
      };
    case DemoActions.DEMO_RESET_DEMO:
      return OU.copyObjectWithoutNulls(action.context) as DemoContext;
    default:
      return state;
  }
}
