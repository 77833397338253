
export enum SettingKey {
  UXDefaultRoute = "UX.Route.Default",
  UXDefaultRouteActivityThreshold = "UX.Route.Default.ActivityThreshold.Seconds",
  UXShowL4LChangedNotifications = "UX.Notifications.L4LChanged",
  UXComponentActionsEnabled = "UX.Component.Actions.Enabled",
  UXContainerDownloadCsvEnabled = "UX.Container.DownloadCSV.Enabled",
  UXComponentTabsEnabledNoLocations = "UX.Component.Tabs.Enabled.NoLocations",
  UXComponentTopNavShowDownloadCSV = "UX.Component.TopNav.Show.Download.CSV",
  UXFeaturesShowSkeletons = "UX.Features.ShowSkeletons",
  UXFeaturesShowErrorComponent = "UX.Features.Show.ErrorComponent"
}
