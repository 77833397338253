import { TooltipBase } from './base-tooltip';

export class KioskTooltip extends TooltipBase {

  public generateTooltipContent(data: any, params?: any): string {
    data = data.data;
    this._logService.debug("Generating StackedArea Tooltip of data: '" + JSON.stringify(data) + "' and params: '" + JSON.stringify(params) + "'");
    let popupHTML = `<table style="width: 95%; max-width: 300px; font-variant: small-caps;">
                    <thead><tr> <td align="center" class="store-popup-title" colspan="3"> <b> ${data.title} </b> </td></tr></thead>`;

    data = data.data;
    Object.keys(data).forEach((key) => {
      popupHTML += `<tr class="store-popup-text">
                        <td align="left" style="vertical-align:middle;"><div>${key}</div></td>
                        <td class="value" style="color:#555555;text-align:right;">${data[key]}</td>
                    </tr>`;
    });
    popupHTML += '</tbody></table>';
    return popupHTML;
  }

}
