import { Action } from "@ngrx/store";
import { DemoContext } from "./demo.reducers";

import { NavAlert } from '../layout/navbar/navAlert.model';

/* tslint:disable max-classes-per-file */
export const ALERT_DISMISSED = 'ALERT_DISMISSED';
export const ADD_PRIORITY_ALERT = 'ADD_PRIORITY_ALERT';
export const DEMO_RESET_DEMO = "DEMO_RESET_DEMO";
export const SET_DEMO_STATE = "SET_DEMO_STATE";

export class AlertDismissed implements Action {
    public readonly type = ALERT_DISMISSED;
    constructor(public payload: string[]) {}
}

export class AddPriorityAlert implements Action {
    public readonly type = ADD_PRIORITY_ALERT;
    constructor(public payload: NavAlert) {}
}

export class SetDemoState implements Action {
  public readonly type = SET_DEMO_STATE;
  constructor(public enabled: boolean) {}
}

export class DemoResetDemo implements Action {
  public readonly type = DEMO_RESET_DEMO;
  constructor(public context: DemoContext) {}
}

export type DemoActions = AlertDismissed | AddPriorityAlert | SetDemoState | DemoResetDemo;
/* tslint:enable max-classes-per-file */
