import { MetaReducer } from "@ngrx/store";

import { Context } from "./context";
import {
  localStorageSyncReducer
} from "./local-storage.settings";

export function getMetaReducers(): MetaReducer<Context> {
  return localStorageSyncReducer;
}
