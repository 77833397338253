import { Routes, RouterModule } from '@angular/router';
import { Layout } from './layout.component';

export enum LayoutRoutes {
  Dashboard = "dashboard"
}

export enum DashboardRoutes {
  Default = "pulse"
}

// noinspection TypeScriptValidateTypes
const routes: Routes = [
  { path: '', component: Layout, children: [
    { path: '', redirectTo: LayoutRoutes.Dashboard, pathMatch: 'full' },
    {
      path: LayoutRoutes.Dashboard,
      loadChildren: () => import('../dashboard/dashboard.module').then((m) => m.DashboardModule)
    }
  ]}
];

export const ROUTES = RouterModule.forChild(routes);
