// tslint:disable:array-type
import {
  TranslateService
} from "@ngx-translate/core";
import * as d3 from "d3";

import {
  BusinessDate
} from "../../model/business-dates/business-date.model";
import { Logger } from "../logging/logger";
import { BaseFormatter } from "./base-formatter";
import {
  FormatterService
} from "./formatter.service";

const DEFAULT: string = "default";
const WEEK_OF_YEAR_FORMAT = "%W";

interface Formatter {
  config: any;
  format: ((bd: BusinessDate, config: any) => string);
}

interface FormatterCollection {
  [type: string]: Formatter;
}

export class BusinessDateChangeBasedFormatter extends BaseFormatter {

  private _previousDate: BusinessDate;
  private _formatters: FormatterCollection;

  constructor(
      locale: d3.Locale,
      config: any,
      translateService: TranslateService,
      formatterService: FormatterService,
      logger: Logger
    ) {
    super(locale, config, translateService, formatterService, logger);
    this._loadDateFormatters(config.formats);
  }

  public formatData(data: any, params?: any): string {
    const bd: BusinessDate = data as BusinessDate;
    const fmtr: Formatter = this._formatter(bd);
    this._previousDate = bd;
    const stringVal = fmtr.format(bd, fmtr.config);
    return this.translatePlaceholders(fmtr.format(bd, fmtr.config));
  }

  private _formatter(bd: BusinessDate): Formatter {
    let lookupKey = BusinessDate.dateElementChanged(bd, this._previousDate);
    lookupKey = lookupKey !== undefined ? lookupKey : DEFAULT;
    return this._formatters[lookupKey];
  }

  private _loadDateFormatters(formatConfig: object): FormatterCollection {
    this._logger.debug("BusinessDateChangeBasedFormatter loaded with config ", {config: formatConfig});
    if (typeof formatConfig[DEFAULT] !== "string") {
      throw new Error("Default format missing from formats!");
    }
    const ret: FormatterCollection = {};
    for (const key of Object.keys(formatConfig)) {
      this._loadFormatter(ret, key, formatConfig[key]);
    }
    return ret;
  }

  private _loadFormatter(collection: FormatterCollection, key: string, config: object): void {
    const fmtr: Formatter = {
      config,
      format: this._formatBusinessDate
    };
    collection[key] = fmtr;
  }

  private _formatBusinessDate(date: BusinessDate, config: any): string {
    const ret = date.year + "-" + date.week;
    return ret;
  }

}
