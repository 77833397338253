import { TooltipBase } from "./base-tooltip";
import { BusinessDate } from "../../model/business-dates/business-date.model";

import * as GQL from "../../util/graphql-tags";
import { getLegendIcon } from "./tooltip-helper";

const KEY: string = "key";
const DATA: string = "data";
const COLOUR: string = "color";
const VALUE: string = "y";

export class MultiBarTooltip extends TooltipBase {

  public generateTooltipContent(data: any, params?: any): string {
    this._logService.debug("Generating MultiBar Tooltip of data ", {tooltipData: data, parameter: params});
    const formatterConfig = params.formatter || this._config.formatters;
    const formatter = this._formatterService.formatter(formatterConfig.value);
    const headerFormatter = this._formatterService.formatter(formatterConfig.header);
    const color = data.color || data.point.color;
    data = data[DATA] || data.point;
    const type = data.type;
    const businessDate: BusinessDate = data[GQL.BUSINESS_DATE];

    const header = headerFormatter.formatData(businessDate);
    const popupHTML: string = `
    <table width="95%" style="font-variant: small-caps;">
      <thead>
        <tr>
          <td colspan="3">${header}</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td align="right" class="legend-icon legend-color-guide">
            ${getLegendIcon(color, type)}
          </td>
          <td class="key" style="text-align: left;">${data[KEY] || data.seriesDefinition.translatedName}</td>
          <td class="value">${formatter.formatData(data[VALUE])}</td>
        </tr>
      </tbody>
    </table>`;
    return popupHTML;
  }

}
