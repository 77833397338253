import {
  JsonObject,
  JsonProperty
} from "json2typescript";
import { TranslateService } from "@ngx-translate/core";

import {
  Series
} from "../dashboard/components/config/series";
import * as GQL from "../util/graphql-tags";
import { DOT } from "../util/string-constants";
import {
  Translations
} from "./config/translations.model";
import { Entity } from "./entity.model";
import {
  LocationType
} from "./location-type.model";
import { Location } from "./location.model";
import {
  NameType,
  NameTypeDefaults
} from "./name-type.enum";
import { Person } from "./person.model";
import { Region } from "./region.model";
import {
  Snapshot
} from "./snapshots/snapshot.model";

const _ID = "ID";

interface RawScope {
  translationKey ?: string;
  type ?: string;
  id ?: string;
  name ?: string;
  contextKey ?: string;
}

export interface ReferenceInput {
  type: string;
  id: string;
}

@JsonObject
export class Reference {

  @JsonProperty(GQL.ENTITY, Entity, true)
  public Entity: Entity = null;

  @JsonProperty(GQL.REGION, Region, true)
  public Region: Region = null;

  @JsonProperty(GQL.LOCATION, Location, true)
  public Location: Location = null;

  @JsonProperty(GQL.LOCATION_TYPE, LocationType, true)
  public LocationType: LocationType = null;

  @JsonProperty(GQL.PERSON, Person, true)
  public Person: Person = null;

  @JsonProperty(GQL.SERIES, Series, true)
  public Series: Series = null;

  @JsonProperty(GQL.SNAPSHOT, Snapshot, true)
  public Snapshot: Snapshot = null;

  @JsonProperty(GQL.SCALAR_NAME_REF, String, true)
  private _nameRef: string = null;

  private _rawScope: RawScope = null;
  private _scope: string = null;

  public icon(translateService: TranslateService) {
    if (null == this._nameRef) {
      throw Error("Attempt to call icon method on a Reference object when the nameRef property was not set on that object!")
    }
    return translateService.instant(this._nameRef + NameTypeDefaults.Icon);
  }

  public name(translateService: TranslateService, nameType: NameTypeDefaults) {
    if (null == this._nameRef) {
      throw Error("Attempt to call name method on a Reference object when the nameRef property was not set on that object!")
    }
    return translateService.instant(this._nameRef + nameType);
  }

  public get referenceName(): string {
    return this._getRawScope().name;
  }

  public scope(
    translateService: TranslateService,
    translations: Translations,
    nameType?: NameType
  ): string {
    if (null !== this._scope) {
      return this._scope;
    }

    const rawScope = this._getRawScope();
    const translation = translations.Translation(rawScope.type);
    const prefix = translation.prefix;
    const suffix = translation.Suffixes.Suffix(nameType);

    const translationKey = prefix + rawScope.type + DOT + rawScope.id.toUpperCase() + suffix;
    const translatedScope = translateService.instant(translationKey);
    if (translatedScope === translationKey) {
      // No translation so just use the value we were given.
      this._scope = rawScope.name;
    } else {
      this._scope = translatedScope;
    }
    return this._scope;
  }

  public toReferenceInput(): ReferenceInput {
    const rs = this._getRawScope();
    const ret: ReferenceInput = {
      type: rs.type,
      id: rs.id
    };
    return ret;
  }

  private _getRawScope(): RawScope {

    if (null !== this._rawScope) {
      return this._rawScope;
    }

    let scope: RawScope = {};

    if (null !== this.Entity) {
      scope.id = this.Entity.id;
      scope.name = this.Entity.name;
      scope.type = GQL.ENTITY;
    } else if (null !== this.Region) {
      scope.id = this.Region.id;
      scope.name = this.Region.name;
      scope.type = GQL.REGION;
    } else if (null !== this.LocationType) {
      scope.id = this.LocationType.id;
      scope.name = this.LocationType.nameRef;
      scope.type = GQL.LOCATION_TYPE;
    } else if (null !== this.Location) {
      scope.id = this.Location.id;
      scope.name = this.Location.name;
      scope.type = GQL.LOCATION;
    } else if (null !== this.Person) {
      scope.id = this.Person.id;
      scope.name = this.Person.displayName;
      scope.type = GQL.PERSON;
    } else if (null !== this.Series) {
      scope.id = this.Series.id;
      scope.name = this.Series.name;
      scope.type = GQL.SERIES;
    } else if (null !== this.Snapshot) {
      scope.id = this.Snapshot.guid;
      scope.name = this.Snapshot.description;
      scope.type = GQL.SNAPSHOT;
    } else {
      scope = undefined;
    }

    if (scope !== undefined) {
      scope.translationKey = scope.type.toUpperCase();
      scope.contextKey = scope.type.toLowerCase() + _ID;
    }

    this._rawScope = scope;
    return this._rawScope;
  }

}
