/*
 * Angular 2 decorators and services
 */
import {
  Component,
  OnInit,
  ViewEncapsulation
} from "@angular/core";
import * as d3 from "d3";

import {
  LocaleService
} from "./services/locale/locale.service";
import {
  Logger
} from "./services/logging/logger";
import {
  LoggingService
} from "./services/logging/logging.service";

// This fix is with respect to DEMO-93 - Tooltips of chart are not cleared on scroll.
const TOUCH_END_EVENT: string = "touchend";
const LISTEN_ON_ELEMENT: string = "body";
const SVG_CLASS: string = "nvd3-svg";
const TOOLTIP_CLASS: string = ".nvtooltip";
const SVG_TAG: string = "svg";
const OPACITY: string = "opacity";
const ZERO_OPACITY: string = "0";
const LOG_NAMESPACE: string = "AppComponent";

/*
 * App Component
 * Top Level Component
 */
@Component({
  selector: 'ddc-app',
  // tslint:disable-next-line:use-view-encapsulation
  encapsulation: ViewEncapsulation.None,
  styleUrls: [
    './scss/application.scss'
  ],
  template: `<router-outlet></router-outlet>`
})
export class AppComponent implements OnInit {

  public _logger: Logger;

  constructor(private locale: LocaleService, logService: LoggingService) {
    this._logger = new Logger(LOG_NAMESPACE, logService);
  }

  public ngOnInit() {
    // specify a namespace for the logs(should be same as the namespace used in logger.service.ts)
    // this._logservice.namespace = 'ALL';
    // All of these methods support passing in any object as a second parameter
    const bodyElement = document.getElementsByTagName(LISTEN_ON_ELEMENT);
    bodyElement[0].addEventListener(TOUCH_END_EVENT, (event: TouchEvent) => { this._clearChartTooltips(event); }, false);
  }

  private _isElementWithInSVGContainer(el: any, cls: string): boolean {
    if (el.tagName === SVG_TAG && el.classList.contains(cls)) {
      return true;
    }
    /*
    * The while loop waits until el has the desired class, and it sets el to el's parent every iteration so in the end,
    * we have the ancestor with that class or null.
    */
    while (el && !el.classList.contains(cls)) {
      el = el.parentElement;
    }
    if (el) {
      return true;
    }
    return false;
  }

  private _clearChartTooltips(event: TouchEvent): void {
    /*
    * Checking if the clicked contaainer is of svg, if it is svg no action is performed.
    * Because nvd3 takes care of clearing the tooltips on any svg click and if the click is performed in svg container
    * with an intention to open tooltip, I should not be clearing it. Hence not considering click/touch with in svg container.
    * I am handling only cases, when the touch is performed any where on the screen and hiding the tooltips.
    */
    d3.selectAll(TOOLTIP_CLASS).style(OPACITY, ZERO_OPACITY);

    const isTouchedInSVG = this._isElementWithInSVGContainer(event.target, SVG_CLASS);
    if (!isTouchedInSVG) {
      /*
      * When nvd3 tooltip is opened, the opacity is set to 1, so setting it to 0 will hide tooltip.
      */
      d3.selectAll(TOOLTIP_CLASS).style(OPACITY, ZERO_OPACITY);
    }
  }
}
