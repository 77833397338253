import { JsonObject, JsonProperty } from "json2typescript";
import * as GQL from "../../util/graphql-tags";
import { copyObjectWithoutGraphQLMetaKeys } from "../../util/object-utils";
import { Reference } from "../reference.model";

@JsonObject
export class Event {

  public static ToValidContextObject(toClean: Event): Event {
    if (null == toClean) {
      return null;
    }

    return copyObjectWithoutGraphQLMetaKeys(toClean) as Event;
  }

  public static ToValidInputObject(toStrip: Event): Event {
    if (null == toStrip) {
      return null;
    }
    const ret: Event = copyObjectWithoutGraphQLMetaKeys(toStrip) as Event;
    if (null != ret) {
      delete ret[GQL.REFERENCE];
      delete ret[GQL.SCALAR_NAME_REF];
    }
    return ret;
  }

  @JsonProperty(GQL.SCALAR_GUID, String, true)
  public guid?: string = undefined;

  @JsonProperty(GQL.SCALAR_ID, String, false)
  public id: string = undefined;

  @JsonProperty(GQL.SCALAR_NAME_REF, String, true)
  public nameRef?: string = undefined;

  @JsonProperty(GQL.SCALAR_YEAR, Number, false)
  public year: number = undefined;

  @JsonProperty(GQL.SCALAR_MONTH, Number, true)
  public month?: number = undefined;

  @JsonProperty(GQL.SCALAR_QUARTER, Number, true)
  public quarter?: number = undefined;

  @JsonProperty(GQL.SCALAR_WEEK, Number, true)
  public week?: number = undefined;

  @JsonProperty(GQL.SCALAR_DAY, Number, true)
  public day?: number = undefined;

  @JsonProperty(GQL.REFERENCES, Reference, true)
  public Reference?: Reference;
}
