import { Timestamp } from '../timestamp.model';
import { JsonObject, JsonProperty } from 'json2typescript';
import * as GQL from "../../util/graphql-tags";

@JsonObject
export class CalendarDateRange {

  @JsonProperty(GQL.FROM, Timestamp, true)
  public From?: Timestamp = undefined;

  @JsonProperty(GQL.TO, Timestamp, true)
  public To?: Timestamp = undefined;
}
