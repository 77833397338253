import { Action } from "@ngrx/store";

import {
  BusinessDateRange
} from "../model/business-dates/business-date-range.model";
import {
  BusinessDate
} from "../model/business-dates/business-date.model";
import { TemporalContext, BusinessDateRangeDictionary } from "./temporal.reducers";

import { TemporalAggregation, PeriodOnPeriodType, DateType } from "../model/business-dates/temporal-aggregation.enum";
import { Timestamp } from "../model/timestamp.model";
import { Event } from "../model/events/event.model";

/* tslint:disable max-classes-per-file */
export const BUSINESS_DATE_RANGE_CHANGED = "BUSINESS_DATE_RANGE_CHANGED";
export const BUSINESS_DATE_RANGE_CHANGE_COMPLETE = "BUSINESS_DATE_RANGE_CHANGE_COMPLETE";
export const CURRENT_BUSINESS_DATE_CHANGED = "CURRENT_BUSINESS_DATE_CHANGED";
export const CURRENT_BUSINESS_DATE_CHANGE_COMPLETE = "CURRENT_BUSINESS_DATE_CHANGE_COMPLETE";
export const TEMPORAL_DEMO_RESET = "TEMPORAL_DEMO_RESET";
export const BUSINESS_DATE_RANGE_AND_TEMPORAL_AGGREGATION_CHANGED = "BUSINESS_DATE_RANGE_AND_TEMPORAL_AGGREGATION_CHANGED";
export const BUSINESS_DATE_RANGE_AND_TEMPORAL_AGGREGATION_CHANGE_COMPLETE = "BUSINESS_DATE_RANGE_AND_TEMPORAL_AGGREGATION_CHANGE_COMPLETE";
export const CALENDAR_DATE_RANGE_AND_TEMPORAL_AGGREGATION_CHANGED = "CALENDAR_DATE_RANGE_AND_TEMPORAL_AGGREGATION_CHANGED";
export const CALENDAR_DATE_RANGE_AND_TEMPORAL_AGGREGATION_CHANGE_COMPLETE = 'CALENDAR_DATE_RANGE_AND_TEMPORAL_AGGREGATION_CHANGE_COMPLETE';
export const L4L_CHANGED = "L4L_CHANGED";
export const DATETYPE_CHANGED = "DATETYPE_CHANGED";
export const SAVE_TEMPORAL_CONTEXT = 'SAVE_TEMPORAL_CONTEXT';
export const RESTORE_TEMPORAL_CONTEXT = 'RESTORE_TEMPORAL_CONTEXT';

export class SaveTemporalContext implements Action {
  public readonly type = SAVE_TEMPORAL_CONTEXT;
  constructor(public dashboardID: string) {}
}

export class RestoreTemporalContext implements Action {
  public readonly type = RESTORE_TEMPORAL_CONTEXT;
  constructor(public dashboardID: string) {}
}
export class BusinessDateRangeChanged implements Action {
  public readonly type = BUSINESS_DATE_RANGE_CHANGED;
  constructor(public payload: BusinessDateRange) {}
}

export class BusinessDateRangeChangeComplete implements Action {
  public readonly type = BUSINESS_DATE_RANGE_CHANGE_COMPLETE;
  constructor(public payload: BusinessDateRangeDictionary) {}
}

export class CurrentBusinessDateChanged implements Action {
  public readonly type = CURRENT_BUSINESS_DATE_CHANGED;
  constructor(public payload: BusinessDate) {}
}

export class CurrentBusinessDateChangeComplete implements Action {
  public readonly type = CURRENT_BUSINESS_DATE_CHANGE_COMPLETE;
  constructor(public payload: TemporalContext) {}
}

export class BusinessDateRangeAndTemporalAggregationChanged implements Action {
  public readonly type = BUSINESS_DATE_RANGE_AND_TEMPORAL_AGGREGATION_CHANGED;
  constructor(
    public businessDateRange?: BusinessDateRange,
    public temporalAggregation?: TemporalAggregation,
    public baselineBusinessDate?: BusinessDate,
    public convertToAbsolute = false,
    public dateType: DateType = DateType.DEFAULT,
    public temporalOffset: any = null
  ) {}
}

export class BusinessDateRangeAndTemporalAggregationChangeComplete implements Action {
  public readonly type = BUSINESS_DATE_RANGE_AND_TEMPORAL_AGGREGATION_CHANGE_COMPLETE;
  constructor(public businessDateRanges: BusinessDateRangeDictionary, public temporalAggregation: TemporalAggregation, public dateType: DateType) {}
}

export class CalendarDateRangeAndTemporalAggregationChangeComplete implements Action {
  public readonly type = CALENDAR_DATE_RANGE_AND_TEMPORAL_AGGREGATION_CHANGE_COMPLETE;
  constructor(public businessDateRanges: BusinessDateRangeDictionary, public temporalAggregation: TemporalAggregation, public dateType: DateType) {}
}
export class L4LChanged implements Action {
  public readonly type = L4L_CHANGED;
  constructor(public payload: PeriodOnPeriodType, public notifyUser: boolean = true) { }
}
export class DateTypeChanged implements Action {
  public readonly type = DATETYPE_CHANGED;
  constructor(public payload: DateType) { }
}
export class CalendarDateRangeAndTemporalAggregationChanged implements Action {
  public readonly type = CALENDAR_DATE_RANGE_AND_TEMPORAL_AGGREGATION_CHANGED;
  constructor(public timestamp: Timestamp, public temporalAggregation: TemporalAggregation, public dateType: DateType) {}
}

export class TemporalDemoReset implements Action {
  public readonly type = TEMPORAL_DEMO_RESET;
  constructor(public payload: TemporalContext) {}
}

export type TemporalActions =
  BusinessDateRangeChanged |
  BusinessDateRangeChangeComplete |
  CurrentBusinessDateChanged |
  CurrentBusinessDateChangeComplete |
  BusinessDateRangeAndTemporalAggregationChanged |
  BusinessDateRangeAndTemporalAggregationChangeComplete |
  CalendarDateRangeAndTemporalAggregationChangeComplete |
  TemporalDemoReset |
  L4LChanged |
  DateTypeChanged;
/* tslint:enable max-classes-per-file */
