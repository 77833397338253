import { Action } from "@ngrx/store";

import {
  MutationType
} from "../dashboard/components/config/mutation-type.enum";
import {
  Comment
} from "../model/comments/comment.model";
import { ReferenceInput } from "../model/reference.model";
import {
  TagValueUpdates
} from "../model/tags/tag-value-updates.model";
import { Task } from "../model/tasks/task.model";

/* tslint:disable max-classes-per-file */
export const ADD_COMMENT = "ADD_COMMENT";
export const ADD_TASK_COMMENT = "ADD_TASK_COMMENT";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const ADD_TASK = "ADD_TASK";
export const UPDATE_TASK = "UPDATE_TASK";
export const ADD_SNAPSHOT_USING_CONTEXT = "ADD_SNAPSHOT_USING_CONTEXT";

export const UPDATE_TAG_VALUES = "UPDATE_TAG_VALUES";

export const MUTATION_SUCCESSFUL = "MUTATION_SUCCESSFUL";
export const MUTATION_FAILED = "MUTATION_FAILED";

export class AddComment implements Action {
    public readonly type = ADD_COMMENT;
    constructor(public payload: Comment) {}
}

export class AddTaskComment implements Action {
  public readonly type = ADD_TASK_COMMENT;
  constructor(public payload: Comment) {}
}

export class DeleteComment implements Action {
  public readonly type = DELETE_COMMENT;
  constructor(public payload: Comment) {}
}

export class AddTask implements Action {
  public readonly type = ADD_TASK;
  constructor(public task: Task, public primaryReference: ReferenceInput, public additionalReferences: ReferenceInput[]) {}
}

export class UpdateTask implements Action {
  public readonly type = UPDATE_TASK;
  constructor(public task: Task) {}
}

export class AddSnapshotUsingContext implements Action {
  public readonly type = ADD_SNAPSHOT_USING_CONTEXT;
  constructor(public payload: {snapshotGUID: string}) {}
}

export class UpdateTagValues implements Action {
  public readonly type = UPDATE_TAG_VALUES;
  constructor(public payload: TagValueUpdates) {}
}

export class MutationSuccessful implements Action {
  public readonly type = MUTATION_SUCCESSFUL;
  constructor(public payload: MutationType[], public translationSuffix: string = null, public typeName: string = null) {
  }
}

export class MutationFailed implements Action {
  public readonly type = MUTATION_FAILED;
  constructor(public err: string, public payload: MutationType[], public translationSuffix: string = null, public typeName: string = null) {
  }
}

export type MutationActions = AddComment | AddTaskComment | DeleteComment | AddTask | UpdateTask | AddSnapshotUsingContext | MutationSuccessful | MutationFailed;
/* tslint:enable max-classes-per-file */
