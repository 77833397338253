import { Routes } from '@angular/router';

import { ErrorComponent } from './error/error.component';
import { IsAuthenticatedGuard } from './services/auth/is-authenticated.guard';

export enum AppRoutes {
  Application = "app",
  Login = "login",
  Error = "error",
  Canary = "canary"
}

export const ROUTES: Routes = [{
   path: '', redirectTo: AppRoutes.Application, pathMatch: 'full'
  },
  {
    path: AppRoutes.Application,
    canActivateChild: [IsAuthenticatedGuard],
    loadChildren: () => import('./layout/layout.module').then((m) => m.LayoutModule)
  },
  {
    path: AppRoutes.Login, loadChildren: () => import('./login/login.module').then((m) => m.LoginModule)
  },
  {
    path: AppRoutes.Canary,
    loadChildren: () => import('./canary/canary.module').then((m) => m.CanaryModule)
  },
  {
    path: AppRoutes.Error, component: ErrorComponent
  },
  {
    path: '**', component: ErrorComponent
  }
];
