import {
  TranslateService
} from "@ngx-translate/core";

import { Logger } from "../logging/logger";
import {
  FormatterService
} from "./formatter.service";
import {
  IFormatter
} from "./i-formatter.interface";

export class BaseFormatter implements IFormatter {

  private _placeholderTranslationKeys: {[id: string]: string};

  public constructor(
                protected locale: d3.Locale,
                protected config: any,
                protected _translateService: TranslateService,
                protected _formatterService: FormatterService,
                protected _logger: Logger) {
    this._placeholderTranslationKeys = config.translations;
  }

  public formatData(data: any, params?: any): string {
    throw new Error("Method not implemented.");
  }

  protected translatePlaceholders(s: string): string {
    const regex = /(?:\$\{)([^\}]*)(?:\})/g;
    const matches: RegExpMatchArray = s.match(regex);
    if (!matches || matches.length === 0) {
      return s;
    }
    for (const item of matches) {
      // Remove ${ from start and } from end
      const lookupKey = item.slice(2, item.length - 1);
      const translationKey = this._placeholderTranslationKeys[lookupKey];
      if (!translationKey || translationKey.length === 0) {
        continue;
      }
      const translationValue = this._translateService.instant(translationKey);
      s = s.replace(item, translationValue);
    }
    return s;
  }

}
