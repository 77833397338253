export const getLegendIcon = (color: string, type: string) => {

  if (type === "line") {
    return `<div class="legend-icon legend-color-guide-line"
              style="border-color: ${color}; border-width: 2px; border-radius: 50%;"></div>`
  }

  if (type === "dashed" || type === "dash") {
    return `<div class="legend-icon legend-color-guide-dotted-line"
    style="border: 2px dashed ${color}; border-width: 2px; border-radius: 50%;"></div>`
  }

  if (type === "additional") {
    return `<div class="legend-icon legend-color-guide-dotted-line"
    style="border: 2px dashed transparent; border-width: 2px; border-radius: 50%;"></div>`
  }

  return `<div style="border: none; background-color: ${color}"></div>`
}
