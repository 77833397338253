import { Action } from "@ngrx/store";

import {
  Alert
} from "../model/alert/alert.model";

/* tslint:disable max-classes-per-file */
export const ALERT_SELECTED = "ALERT_SELECTED";
export const ALERT_DESELECTED = "ALERT_DESELECTED";
export const DASHBOARD_ACTIVATED = "DASHBOARD_ACTIVATED";
export const DASHBOARD_DEACTIVATED = "DASHBOARD_DEACTIVATED";
export const UX_RESET = "UX_RESET";
export const QUERY_ERROR = "QUERY_ERROR";

export class AlertSelected implements Action {
  public readonly type = ALERT_SELECTED;
  constructor(public payload: Alert) {}
}

export class AlertDeSelected implements Action {
  public readonly type = ALERT_DESELECTED;
  constructor(public payload: Alert) {}
}

export class DashboardActivated implements Action {
  public readonly type = DASHBOARD_ACTIVATED
  constructor(public payload: string) {}
}

export class DashboardDeactivated implements Action {
  public readonly type = DASHBOARD_DEACTIVATED
  constructor(public payload: string) {}
}

export class UXReset implements Action {
  public readonly type = UX_RESET;
  constructor(public context: any) {}
}

export class QueryError implements Action {
  public readonly type = QUERY_ERROR;
  constructor(public context: any) {}
}

export type UXActions = AlertSelected | AlertDeSelected | DashboardActivated | DashboardDeactivated | UXReset;
/* tslint:enable max-classes-per-file */
