import { Injectable } from "@angular/core";
import {
  TranslateService
} from "@ngx-translate/core";

import {
  formattedTimeString
} from "../../util/utils";
import {
  BrandService
} from "../brand/brand.service";
import {
  FormatterService
} from "../formatter/formatter.service";
import { Logger } from "../logging/logger";
import {
  LoggingService
} from "../logging/logging.service";
import {
  DimensionHorizontalBarChartTooltip
} from "./dimensionHorizontalBarChartTooltip";
import {
  DiscreteBarTooltip
} from "./discrete-bar-tooltip";
import {
  HistogramTooltip
} from "./histogramTooltip";
import {
  HorizontalBarChartTooltip
} from "./horizontalBarChartTooltip";
import { ITooltip } from "./i-tooltip.interface";
import { KioskTooltip } from "./kiosk-tooltip";
import {
  SalesVSCsatTooltip
} from "./line-chart-sales-vs-csat-tooltip";
import { MapTooltip } from "./mapTooltip";
import {
  MultiBarTooltip
} from "./multiBarTooltip";
import { MultiBarTooltipWithComponentData } from "./multiBarTooltipWithComponentData";
import {
  MultiChartTooltip
} from "./multiChartTooltip";
import {
  MultiDimensionHorizontalBarTooltip
} from "./multiDimensionHorizontalBarTooltip";
import {
  MultiDimensionHorizontalBarVerticalLayoutTooltip
} from "./multiDimensionHorizontalBarVerticalLayoutTooltip";
import {
  MultiDimensionVerticalBarTooltip
} from "./multiDimensionVerticalBarTooltip";
import {
  StackedAreaTooltip
} from "./stacked-area-tooltip";
import {
  WeatherHorizontalBarChartTooltip
} from "./weatherHorizontalBarChartTooltip";

@Injectable()
export class TooltipService {

  // Lookup table for tooltip instances.
  private tooltips: { [type: string]: ITooltip; } = {};

  private _tooltipSelectorsClassMap: {[type: string]: any; } = {
    multiChartTooltip: MultiChartTooltip,
    mapTooltip1: MapTooltip,
    horizontalBarChartTooltip: HorizontalBarChartTooltip,
    dimensionHorizontalBarChartTooltip: DimensionHorizontalBarChartTooltip,
    lcSalesvsCsatTooltip: SalesVSCsatTooltip,
    histogramTooltip: HistogramTooltip,
    discreteBarTooltip: DiscreteBarTooltip,
    multiBarTooltip: MultiBarTooltip,
    multiBarTooltipWithComponentData: MultiBarTooltipWithComponentData,
    stackedAreaTooltip: StackedAreaTooltip,
    multiDimensionHorizontalBarTooltip: MultiDimensionHorizontalBarTooltip,
    multiDimensionHorizontalBarVerticalLayoutTooltip: MultiDimensionHorizontalBarVerticalLayoutTooltip,
    multiDimensionVerticalBarTooltip: MultiDimensionVerticalBarTooltip,
    kioskTooltip: KioskTooltip,
    weatherHorizontalBarChartTooltip: WeatherHorizontalBarChartTooltip
  };

  private _tooltipConfig: {[type: string]: any} = {
  };
  private _logger: Logger;
  constructor(
              private _formatterService: FormatterService,
              private _brandService: BrandService,
              private _translateService: TranslateService,
              private _loggingService: LoggingService
            ) {
    this._logger = new Logger("TooltipService", _loggingService);
  }

  public init(tooltipConfig: any) {
    this._logger.info(formattedTimeString() + " Initializing Tooltip Service...");
    this._logger.debug("Instantiating TooltipService with config", {config: tooltipConfig});
    for (const fmt of tooltipConfig) {
      const fmtConfig = {
        id: fmt.id,
        type: fmt.type
      };
      if (fmt.config) {
        fmtConfig["config"] = JSON.parse(fmt["config"]);
      }
      this._tooltipConfig[fmt.id] =  fmtConfig;
    }

    this._logger.info("Completed Setting Config to Tooltip Service" + formattedTimeString());
  }

  /*
  * This function will return the instance of the ITooltip.
  * @param string - tooltip type
  * @return - ITooltip instance
  */
  public getTooltip(type: string): ITooltip {
    return this._instantiateTooltip(type);
  }

  /*
  * This function will return the instance of ITooltip by tooltip type.
  * @param string - tooltip type
  * @param Array - Array of arguments
  * @return - ITooltip instance
  */
  private _instantiateTooltip(type: string): ITooltip {
    const tooltipConfig = this._tooltipConfig[type];

    if (tooltipConfig === undefined || this._tooltipSelectorsClassMap[tooltipConfig.type] === undefined) {
      throw new TypeError("Type '" + type + "' not found. ");
    }

    if (this.tooltips[type] !== undefined) {
      return this.tooltips[type];
    }
    this._logger.info("Mapped with the Tooltip class class of type '" + type + "'");
    const instance: ITooltip = new this._tooltipSelectorsClassMap[tooltipConfig.type](
      tooltipConfig.config,
      this._formatterService,
      this._brandService,
      this._translateService,
      new Logger(this._tooltipSelectorsClassMap[tooltipConfig.type].name, this._loggingService));
    this.tooltips[type] = instance;
    return instance;
  }
}
