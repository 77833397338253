import { ActionReducerMap } from "@ngrx/store";

import * as fromDemo from "./demo.reducers";
import * as fromDimension from "./dimension.reducers";
import * as fromReference from "./reference.reducers";
import * as fromTemporal from "./temporal.reducers";
import * as fromUser from "./user.reducers";
import * as fromUX from "./ux.reducers";

export interface Context {
  reference: fromReference.ReferenceContext;
  temporal: fromTemporal.TemporalContext;
  user: fromUser.UserContext;
  demo: fromDemo.DemoContext;
  ux: fromUX.UXContext;
  dimension: fromDimension.DimensionContext;
}

export const reducers: ActionReducerMap<Context> = {
  reference: fromReference.referenceReducer,
  temporal: fromTemporal.temporalReducer,
  user:  fromUser.userReducer,
  demo: fromDemo.demoReducer,
  ux: fromUX.uxReducer,
  dimension: fromDimension.dimensionReducer
};

// tslint:disable:max-classes-per-file

export class ReferenceContextKeys {
  public static LOCATION_ID = "locationID";
  public static LOCATION_TYPE_ID = "locationTypeID";
  public static ENTITY_ID = "entityID";
  public static REGION_ID = "regionID";
  public static ACTIVE_SNAPSHOT = "ActiveSnapshot";
}

export class TemporalContextKeys {
  public static CONTEXT_KEY = "temporal";
  public static FROM = "from";
  public static TO = "to";
  public static LAST = "last";
  public static NEXT = "next";
  public static L4L = "L4L";
  public static DATE_TYPE = "dateType";
}

export class AggregationContextKeys {
  public static CONTEXT_KEY = "aggregation";
  public static TEMPORAL = "temporal";
}

export class UserContextKeys {
  public static CONTEXT_KEY = "user";
  public static PROFILE = "profile";
  public static AUTH = "auth";
  public static MESSAGE = "message";
}

export class DemoContextKeys {
  public static CONTEXT_KEY = "demo";
  public static ALERT_DISMISSED = "dismissedAlerts";
  public static ADD_PRIORITY_ALERT = "priorityAlert";
}

export class UXContextKeys {
  public static CONTEXT_KEY = "ux";
  public static RAISE_EVENT = "raiseEvent";
  public static CLEAR_EVENT = "clearEvent";
}

export class DimensionContextKeys {
  public static CONTEXT_KEY = "dimension";
  public static PRODUCT = "product";
}

// tslint:enable:max-classes-per-file
