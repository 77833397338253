import { TooltipBase } from "./base-tooltip";
import { BusinessDate } from "../../model/business-dates/business-date.model";

import * as GQL from "../../util/graphql-tags";
import { getLegendIcon } from "./tooltip-helper";

const KEY: string = "key";
const DATA: string = "data";
const VALUE: string = "y";

export class MultiBarTooltipWithComponentData extends TooltipBase {

  public generateTooltipContent(data: any, params?: any): string {

    if (null == data.componentData) {
      return;
    }

    this._initializeFormatters(params);

    this._logService.debug("Generating MultiBarTooltipWithComponentData Tooltip of data ", {tooltipData: data.componentData, parameter: params});
    const formatterConfig = params.formatter || this._config.formatters;
    const headerFormatter = this._formatterService.formatter(formatterConfig.header);
    const mainFormatter = this._formatterService.formatter(formatterConfig.value);
    const xIndex = data.data ? data.data.x : data.point.x;
    let rowsHtml = "";
    const items = null == params["order-by-index"] ? data.componentData : params["order-by-index"].map((index: number) => data.componentData[index]);
    items.forEach((rowData: any, rowIndex: number) => {
      let formatter;
      if (params.formatters) {
        formatter = this.formatters[params.formatters[rowIndex]];
      } else {
        formatter = mainFormatter;
      }

      rowsHtml += `<tr>
      <td align="right" class="legend-icon legend-color-guide">
        ${getLegendIcon(params.useDataColor === true ? data.color : rowData.color, rowData.subType || rowData.type)}
      </td>
      <td class="key" style="text-align: left;">${rowData[KEY]}</td>
      <td class="value">${formatter.formatData(rowData.values[xIndex][VALUE])}</td>
    </tr>`
    });

    const dataPoint = data[DATA] || data.point;
    const businessDate: BusinessDate = dataPoint[GQL.BUSINESS_DATE];

    data.index = data.index || data.xIndex || data.value;

    const header = headerFormatter.formatData(businessDate);
    if (null != params.headerRangePrefixes) {
      const dataPoints = [
        data.componentData[params.headerRangeIndexes[0]].values[data.index],
        data.componentData[params.headerRangeIndexes[1]].values[data.index]
      ];
      const headers = params.headerRangePrefixes[0] +
                      headerFormatter.formatData(dataPoints[0].BusinessDate) + " - " +
                      params.headerRangePrefixes[1] +
                      headerFormatter.formatData(dataPoints[1].BusinessDate);
      return this.getTable(headers, rowsHtml);
    }

    return this.getTable(header, rowsHtml);
  }

  private getTable(header: string, rowsHtml: string) {
    return `
    <table width="95%" style="font-variant: small-caps;">
      <thead>
        <tr>
          <td colspan="3">${header}</td>
        </tr>
      </thead>
      <tbody>
        ${rowsHtml}
      </tbody>
    </table>`
  }

}
