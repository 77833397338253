// tslint:disable:max-classes-per-file
import {
  JsonObject,
  JsonProperty
} from "json2typescript";

import {
  ContextualQuery
} from "./context-sensitive";
import {
  MutationTypeArrayConverter
} from "./mutation-type.converter";
import {
  MutationType
} from "./mutation-type.enum";

@JsonObject
export class DataRegistrationConfig {

    @JsonProperty("refresh-on-mutation-types", MutationTypeArrayConverter, true)
    public refreshOnMutationTypes: MutationType[] = [];

    @JsonProperty("default", ContextualQuery, true)
    public default: ContextualQuery = null;

    @JsonProperty("context-sensitive", [ContextualQuery], true)
    public contextualQueries: ContextualQuery[] = null;

}
