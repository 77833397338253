import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";

import {
  MutationType
} from "../dashboard/components/config/mutation-type.enum";
import {
  Period
} from "../model/alert/period.model";
import {
  DataService
} from "../services/data/data.service";
import {
  copyObjectWithoutGraphQLMetaKeys
} from "../util/object-utils";
import { Noop } from "./effects";
import {
  ADD_COMMENT,
  ADD_TASK_COMMENT,
  AddComment,
  AddTaskComment,
  DELETE_COMMENT,
  DeleteComment,
  MutationFailed,
  MutationSuccessful
} from "./mutation.actions";
import { Timestamp } from "../model/timestamp.model";
import { switchMap } from "rxjs/operators";
import { DELETED, UPDATED } from "../util/string-constants";

const _LOG_ID = "comment.effects";

// tslint:disable-next-line:max-line-length
const _ADD_COMMENT_GQL = `mutation ($authorID: String!, $primaryReference: ReferenceInput!, $additionalReferences: [ReferenceInput!], $timestamp:DateTimeInput!, $period: PeriodInput!, $comment:String!, $updateGUID: String) {
  AddComment(Author: {id: $authorID}, PrimaryReference: $primaryReference, AdditionalReferences: $additionalReferences, Timestamp: $timestamp, Period: $period, comment: $comment, updateGUID: $updateGUID) {
    guid
    id
    comment
    Author {
      id
      guid
      displayName
      avatarURL
    }
    PrimaryReference {
      Entity {
        id
        guid
        name
      }
      Location {
        id
        guid
        name
      }
      Region {
        id
        guid
        name
      }
    }
  }
}
`;

const _DELETE_COMMENT_GQL = `mutation ($timestamp:DateTimeInput!, $updateGUID: String!) {
  DeleteComment(Timestamp: $timestamp, updateGUID: $updateGUID)
}
`;


const _ADD_TASK_COMMENT_GQL = `mutation ($taskGUID: String!, $authorID: String!, $additionalReferences: [ReferenceInput!], $timestamp:DateTimeInput!, $comment:String!, $replyToGUID: String, $updateGUID: String) {
  AddTaskComment(taskGUID: $taskGUID, Author: {id: $authorID}, AdditionalReferences: $additionalReferences, Timestamp: $timestamp, comment: $comment, replyToGUID: $replyToGUID, updateGUID: $updateGUID) {
    guid
    id
    comment
    Author {
      id
      guid
      displayName
      avatarURL
    }
  }
}
`;

@Injectable()
export class CommentEffects {

  @Effect()
  public commentAdded$ = this._action$
  .pipe(
    ofType(ADD_COMMENT),
    switchMap(async (action: AddComment) => {
    return this.saveComment(action);
    })
  );


  @Effect()
  public taskCommentAdded$ = this._action$
  .pipe(
    ofType(ADD_TASK_COMMENT),
    switchMap(async (action: AddTaskComment) => {
    return this.saveTaskComment(action);
    })
  );


  @Effect()
  public commentDeleted$ = this._action$
  .pipe(
    ofType(DELETE_COMMENT),
    switchMap(async (action: DeleteComment) => {
      console.log("DELETE CALLED");
      return this.deleteComment(action);
    })
  );

  constructor(private _action$: Actions, private _dataService: DataService) {}

  private async saveComment(action: AddComment): Promise<MutationSuccessful | MutationFailed |  Noop> {
    const cmt = action.payload;
    const updateGUID = cmt.id;
    const translationSuffix = updateGUID != null ? UPDATED.toUpperCase() : null;

    const params = {
      authorID: cmt.Author.id,
      primaryReference: cmt.InputReferences.Primary,
      additionalReferences: cmt.InputReferences.Additional.length > 0 ? cmt.InputReferences.Additional : null,
      timestamp: Timestamp.ToValidInputObject(cmt.Timestamp),
      period: (copyObjectWithoutGraphQLMetaKeys((cmt.Period || Period.NO_PERIOD))),
      comment: cmt.comment,
      updateGUID: updateGUID
    };

    try {
      await this._dataService.mutate(_LOG_ID, _ADD_COMMENT_GQL, params);
      console.log("COMMENT SAVED");

      return new MutationSuccessful([MutationType.Comment], translationSuffix);
    } catch (err) {
      console.log(`COMMENT __NOT__ SAVED!:

      ${err}`);
      return new MutationFailed(err, [MutationType.Comment], translationSuffix);
    }
  }


  private async saveTaskComment(action: AddTaskComment): Promise<MutationSuccessful | MutationFailed |  Noop> {
    const cmt = action.payload;
    const updateGUID = cmt.id;
    const translationSuffix = updateGUID != null ? UPDATED.toUpperCase() : null;

    const params = {
      authorID: cmt.Author.id,
      taskGUID: cmt.InputReferences.Additional[0].id,
      additionalReferences: cmt.InputReferences.Additional.length > 0 ? cmt.InputReferences.Additional : null,
      timestamp: Timestamp.ToValidInputObject(cmt.Timestamp),
      comment: cmt.comment,
      updateGUID: updateGUID
    };

    console.log("SAVING THIS TASK COMMENT", params);

    try {
      await this._dataService.mutate(_LOG_ID, _ADD_TASK_COMMENT_GQL, params);
      console.log("TASK COMMENT SAVED");

      return new MutationSuccessful([MutationType.Comment], translationSuffix);
    } catch (err) {
      console.log(`TASK COMMENT __NOT__ SAVED!:

      ${err}`);
      return new MutationFailed(err, [MutationType.Comment], translationSuffix);
    }
  }

  private async deleteComment(action: DeleteComment): Promise<MutationSuccessful | MutationFailed |  Noop> {
    const cmt = action.payload;
    const updateGUID = cmt.id;
    const translationSuffix = DELETED.toUpperCase()

    const params = {
      timestamp: Timestamp.ToValidInputObject(cmt.Timestamp),
      updateGUID: updateGUID
    };


    console.log("DELETE", JSON.stringify(params, null, 2));

    try {
      await this._dataService.mutate(_LOG_ID, _DELETE_COMMENT_GQL, params);
      console.log("COMMENT DELETED");

      return new MutationSuccessful([MutationType.Comment], translationSuffix);
    } catch (err) {
      console.log(`COMMENT __NOT__ DELETED!:

      ${err}`);
      return new MutationFailed(err, [MutationType.Comment], translationSuffix);
    }
  }

}
