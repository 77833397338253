import { Any, JsonObject, JsonProperty } from "json2typescript";
import { DataRegistrationConfig } from "../../../config/data-registration-config";
import { Series } from "../../../config/series";
import { NullValues } from "../../../tables/config/null-values";

@JsonObject
export class CSVExporterConfig {
  @JsonProperty("id", String, true)
  public id: string = undefined;

  @JsonProperty("file-name", String, true)
  public fileName: string = undefined;

  @JsonProperty("brand-selector", String, true)
  public brandSelector: string = undefined;

  @JsonProperty("label-value", String, true)
  public labelValue: string = undefined;

  @JsonProperty("toggle-alert-sidebar", Boolean, true)
  public toggleAlertSidebar: boolean = false;

  @JsonProperty("check-data", Any, true)
  public checkData: any = null;

  @JsonProperty("data-values", Any, true)
  public dataValues: any = false;

  @JsonProperty("icon", String, true)
  public icon: string = undefined;

  @JsonProperty("series", [Series], true)
  public series: Series[] = undefined;

  @JsonProperty("series-groups", Any, true)
  public seriesGroups: any = null;

  @JsonProperty("headers", [Any], true)
  public headers: any[] = null;

  @JsonProperty("replacements", [Any], true)
  public replacements: any[] = null;

  @JsonProperty("rows-expressions", Any, true)
  public rowsExpressions: any = null;

  @JsonProperty("rows", Any, true)
  public rows: any = [];

  @JsonProperty("columns", Any, true)
  public columns: any = [];

  @JsonProperty("null-values", NullValues, true)
  public nullValues: NullValues = undefined;

  @JsonProperty("loop-query", Any, true)
  public loopQuery: any = undefined;

  @JsonProperty("error-notification", Any, true)
  public errorNotification: any = undefined;

  @JsonProperty("data-registration", DataRegistrationConfig, true)
  public dataRegistrationConfig: DataRegistrationConfig = null;
}
