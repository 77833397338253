// Helper classes parse strings into typed enums.
// This means that even though you are specifying a simple string
// in the configuration it WILL be checked to make sure it's a valid
// value.  Also it means the case sensitivity is removed because the enum
// index strings are all upper case while the enum VALUES are in the correct
// case sensitive form.
// So even if you put "locationTYPEid" in the configuration
// it will end up as the (correct) "locationTypeID" in any query.

enum ReferenceSelector {
  LOCATIONID = "locationID",
  PERSONID = "personID",
  DEPARTMENTID = "departmentID",
  LOCATIONTYPEID = "locationTypeID",
  ENTITYID = "entityID",
  PARTNERID = "partnerID",
  REGIONID = "regionID"
}

// Convert a single string into an instance of ReferenceSelector.
function toReferenceSelector(selector: string): ReferenceSelector {
  return ReferenceSelector[selector.toUpperCase()];
}

// Convert an array of strings into an array of ReferenceSelector.
function toReferenceSelectors(selectors: string[]): ReferenceSelector[] {
  return selectors.map((selector) => toReferenceSelector(selector));
}

// Convert a single ReferenceSelector into a string.
function fromReferenceSelector(selector: ReferenceSelector): string {
  return selector.toString();
}

// Convert an array of ReferenceSelector into an array of strings.
function fromReferenceSelectors(selectors: ReferenceSelector[]): string[] {
  return selectors.map((selector) => fromReferenceSelector(selector));
}

export {
  ReferenceSelector,
  fromReferenceSelector,
  fromReferenceSelectors,
  toReferenceSelector,
  toReferenceSelectors
};
