import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams
} from "@ngx-translate/core";

const DDC: string = "DIDICI.";

export class I18nMissingTranslationHandler implements MissingTranslationHandler {

    public handle(params: MissingTranslationHandlerParams) {
      if (params.key.startsWith(DDC)) {
        /*
        * TODO - Have to trigger API that notifies the DidiciCustomerServiceDesk
        * for missing translation.
        */
        const key = params.key.substr(DDC.length);
        return key;
      }
      const newKey = DDC + params.key;
      return params.translateService.instant(newKey, params.interpolateParams);
    }
}
