import { Injectable } from "@angular/core";

import {
  Notification
} from "./notification.interface";

@Injectable()
export class NotificationsService {

  private _messenger: any = null;
  private _config: any;

  public init(_config: any) {
    this._config = JSON.parse(_config);
    this._initMessenger();
  }

  public post(notif: Notification): any {
    if (this._initMessenger()) {
      return this._messenger().post(notif);
    } else {
      console.error("Messenger not able to initialize at this time.  Supplied notification details were:", notif);
    }
  }

  private _initMessenger() {
    if (null != this._messenger) {
      return true;
    }
    this._messenger = (window as any).Messenger || null;
    if (null == this._messenger) {
      console.error("Messenger Not Initialized");
      return false;
    }
    this._messenger.options = this._config.options || {};
    return true;
  }

}
