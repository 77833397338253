import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { QueryOptions } from "apollo-client";
import gql from "graphql-tag";
import * as jp from "jsonpath";

import {
  ContextConfiguration
} from "../../context/config/context.config";
import * as fromContext from "../../context/context";
import {
  AuthenticationCompleted
} from "../../context/user.actions";
import * as fromUser from "../../context/user.reducers";
import {
  Timestamp
} from "../../model/timestamp.model";
import {
  Features
} from "../../model/user/features-model";
import {
  Preferences
} from "../../model/user/preferences-model";
import {
  Settings
} from "../../model/user/settings.model";
import {
  User
} from "../../model/user/user.model";
import {
  formattedTimeString
} from "../../util/utils";
import {
  isAuthenticated,
  storeUserContext
} from "../auth/auth-utils";
import {
  AUTH_PICTURE,
  AUTH_USER_NAME,
  AuthenticationService
} from "../auth/authentication.service";
import {
  BrandService
} from "../brand/brand.service";
import {
  DataService
} from "../data/data.service";
import {
  FormatterService
} from "../formatter/formatter.service";
import {
  LocaleService
} from "../locale/locale.service";
import { Logger } from "../logging/logger";
import {
  LoggingService
} from "../logging/logging.service";
import {
  NotificationsService
} from "../notifications/notifications.service";
import {
  SupportService
} from "../support/support.service";
import {
  TooltipService
} from "../tooltip/tooltip.service";
import { ExporterService } from "../exporter/exporter.service";
import { DEFAULT_AVATAR_URL } from "../../util/string-constants";

export const APP_INITIALIZED = "APP_INITIALIZED";

const DATA = "data";
const PATH_TO_QUERY_CONFIGURATION_AUTH_CONFIG_PAYLOAD = "$..Authentication[*]";
const PATH_TO_QUERY_CONFIGURATION_LOCALES_PAYLOAD = "$..Locales[*]";
const PATH_TO_QUERY_CONFIGURATION_TOOLTIPS_PAYLOAD = "$..Tooltips[*]";
const PATH_TO_QUERY_CONFIGURATION_FORMATTERS_PAYLOAD = "$..Formatters[*]";
const PATH_TO_QUERY_CONFIGURATION_BRAND_SELECTORS_PAYLOAD = "$..BrandSelectors[*]";
const PATH_TO_CONTEXT_CONFIGURATION_PAYLOAD = "$..Configuration.Context";
const PATH_TO_NOTIFICATIONS_SERVICE_PAYLOAD = "$..Configuration.Notifications";
const PATH_TO_EXPORTER_SERVICE_PAYLOAD = "$..Configuration.Exporters";
const PATH_TO_SUPPORT_SERVICE_PAYLOAD = "$..Configuration.Support";


const LOGIN_MUTATION = "mutation login($timestamp: DateTimeInput!) { RecordUserEvent(Event: Login, Timestamp: $timestamp)  }"
const USER_QUERY = gql`{User {guid displayName avatarURL Settings Features Preferences DefaultContext}}`;
const STARTUP_SERVICE_USER_QUERY_ID = "StartupServiceUserQuery";

const STARTUP_QUERY = `
query getStartupConfig($url: String!, $locale: String!) {
  Configuration {
    Authentication(urls: [$url]) {
      id
      guid
      clientID
      domain
      callbackURL
      responseType
      scope
      logoutURL
    }
    Formatters {
        id
        guid
        type
        category
        config
    }
    BrandSelectors {
      id
      type
      guid
      config
      Registrations {
        selector
        selectorProperty
      }
    }
    Tooltips {
      id
      guid
      type
      config
    }
    Locales(ids: [$locale]) {
        id
        decimal
        thousands
        grouping
        currency
        dateTime
        date
        time
        periods
        days
        shortDays
        months
        shortMonths
    }
    Support
    Notifications
    Context
    Exporters
    Demo {
      Data {
        commit
      }
      enabled
    }
  }
}
`;

const LOG_NAMESPACE: string = "services.startup";

@Injectable()
export class StartupService {

    private _startUpConfiguration: any;
    private _logger: Logger;

    constructor( private _store: Store<fromContext.Context>,
                 private _dataService: DataService,
                 private _formatterService: FormatterService,
                 private _brandService: BrandService,
                 private _tooltipService: TooltipService,
                 private _localeService: LocaleService,
                 private _authenticationService: AuthenticationService,
                 private _notificationsService: NotificationsService,
                 private _supportService: SupportService,
                 private _exporterService: ExporterService,
                 _loggingService: LoggingService
               ) {
      this._logger = new Logger(LOG_NAMESPACE, _loggingService);
    }

    public async load(): Promise<any> {
      // TODO When typescript pull request is adopted to allow userLanguage
      // we should remove this cast to any.  The cast is simply there to remove
      // a compiler error we know isn't really an error...
      // tslint:disable-next-line:max-line-length
      // Some browsers return lower case versions of either half of a locale so we lower case it and pass that in.  The back end is expecting a lower case file name and all files in the locale folder are lower cased names now.
      const userLocale = (navigator.language ? navigator.language : (navigator as any).userLanguage).toLowerCase();

      const queryOptions: QueryOptions = {
        query: gql`${STARTUP_QUERY}`,
        variables: {
          locale: userLocale,
          url: window.location.hostname + ((window.location.port) ? ':' + window.location.port :  '')
        }
      };
      this._logger.info("Calling Data Service to get configuration for Startup service with Query and variables ",
        {query : STARTUP_QUERY, variables: queryOptions.variables});
      try {
        const data = await this._dataService.runQuery("StartUpService", queryOptions);
        this._logger.info("Received response from from GQL " + formattedTimeString());
        console.log("RESPONSE", data);
        const ret = await this._instantiateStartupServices(data);
        console.warn("Startup Service has finished.");
        return ret;
      } catch (err) {
        this._logger.error(err);
      }

    }

    public async _instantiateStartupServices(data: any) {
      this._logger.warn("Instantiating StartUp Service");
      const configData: any = data[DATA];
      this._logger.warn("Instantiating StartUp Service with data ", {data: configData});
      this._authenticationService.init(jp.query(configData, PATH_TO_QUERY_CONFIGURATION_AUTH_CONFIG_PAYLOAD)[0]);
      this._localeService.init(jp.query(configData, PATH_TO_QUERY_CONFIGURATION_LOCALES_PAYLOAD)[0]);
      this._brandService.init(jp.query(configData, PATH_TO_QUERY_CONFIGURATION_BRAND_SELECTORS_PAYLOAD));
      this._formatterService.init(jp.query(configData, PATH_TO_QUERY_CONFIGURATION_FORMATTERS_PAYLOAD));
      this._tooltipService.init(jp.query(configData, PATH_TO_QUERY_CONFIGURATION_TOOLTIPS_PAYLOAD));
      this._logger.warn(formattedTimeString() + " Initializing Notification Service...");
      this._notificationsService.init(jp.query(configData, PATH_TO_NOTIFICATIONS_SERVICE_PAYLOAD)[0] || {});
      const userContext: fromUser.UserContext = await this.authenticateAndGetUserContext();
      if (null == userContext) {
        this._logger.warn("User is not authenticated.  Context service not initialized.");
        return;
      }
      await this._supportService.init(jp.query(configData, PATH_TO_SUPPORT_SERVICE_PAYLOAD)[0] || {});
      this._logger.info(formattedTimeString() + " Initializing Context Service...");
      ContextConfiguration.init(jp.query(configData, PATH_TO_CONTEXT_CONFIGURATION_PAYLOAD)[0] || {});
      this._exporterService.init(jp.query(configData, PATH_TO_EXPORTER_SERVICE_PAYLOAD)[0] || {});
      this._store.dispatch(new AuthenticationCompleted(userContext));
    }

    private async authenticateAndGetUserContext(): Promise<fromUser.UserContext | null> {
      const authenticationResult = await this._authenticationService.authenticate();
      if (isAuthenticated().isTokenExpired) {
        this._logger.warn("User is not authenticated.  Cannot continue!");
        return Promise.resolve(null);
      }
      this._logger.warn("Auth0 Authentication succeeded.  Retrieving user profile details from Auth0...");
      const res = await this._authenticationService.getUserProfile(authenticationResult.auth.accessToken);
      if (!res || res.isError) {
        this._logger.error("Could not retrieve user profile data from Auth0!");
        return Promise.resolve(null);
      }
      const userProfile = res.userProfile;

      this._logger.warn("Recording user login...");
      const timestamp = Timestamp.ToValidInputObject(Timestamp.now());

      let result = await this._dataService.mutate(STARTUP_SERVICE_USER_QUERY_ID, LOGIN_MUTATION, {timestamp});

      this._logger.warn("Retrieving user profile details from DDC API...");
      result = await this._dataService.runQuery(STARTUP_SERVICE_USER_QUERY_ID, {query: USER_QUERY})
      const data = result.data;
      const newUser = new User();
      newUser.id = userProfile.sub;
      newUser.email = userProfile.email;
      newUser.displayName = userProfile[AUTH_USER_NAME] ? userProfile[AUTH_USER_NAME] : userProfile.name;
      newUser.nickName = userProfile.nickname;
      if (null != data.User) {
        newUser.avatarURL = data.User.avatarURL;
        newUser.guid = data.User.guid;
        newUser.Features = new Features(data.User.Features);
        newUser.Settings = new Settings(data.User.Settings);
        newUser.Preferences = new Preferences(data.User.Preferences);
        newUser.DefaultContext = JSON.parse(data.User.DefaultContext);
      }
      if (!newUser.avatarURL) {
        newUser.avatarURL = userProfile[AUTH_PICTURE] ? userProfile[AUTH_PICTURE] : DEFAULT_AVATAR_URL;
      }

      // Save authentication details immediately so callbacks which cause total reloads still work when ngrx not yet initialized.
      const ret: any = {
        auth: {
          ...authenticationResult.auth,
          authorization: res.authorization
        },
        profile: newUser
      };
      storeUserContext(ret);
      return Promise.resolve(ret);

    }

    get startupConfig(): any {
        return this._startUpConfiguration;
    }
}
