import { ISelectorProperties } from "./selector.properties.interface";
import { ISelector } from "./selector.interface";
import { BrandService } from './brand.service';
import { Logger } from '../logging/logger';
import { IAlertProperties } from './alert.properties.interface';
/*
* This will contain all default colors or the custom array of colors or
* series based color mapping.
*/
export abstract class BaseSelector implements ISelector  {

  public constructor(private _code: string, private _config: any, protected _brandService: BrandService, protected _logger: Logger) {
  }

  protected get config(): any {
    return this._config;
  }

  protected get code(): string {
    return this._code;
  }

  public seriesColour(properties: ISelectorProperties): string {
    throw new Error('Not implemented in base class!');
  }

  public seriesLineStyle(properties: ISelectorProperties): string {
    throw new Error ('Not implemented in base class!');
  }

  public alertContainerStyle(properties: ISelectorProperties): {[type: string]: any}  {
    throw new Error ('alertContainerStyle not implemented in base class!');
  }

  public alertTextStyle(properties: ISelectorProperties): {[type: string]: any}  {
    throw new Error ('alertTextStyle not implemented in base class!');
  }

  public alertIcon(properties: ISelectorProperties): string {
    throw new Error ('alertIcon not implemented in base class!');
  }

  public alertIconUnicode(properties: ISelectorProperties): string {
    throw new Error ('alertIconUnicode not implemented in base class!');
  }

  public alertProperties(properties: ISelectorProperties): IAlertProperties {
    throw new Error ('alertProperties not implemented in base class!');
  }

  public namedColour(name: string): string {
    throw new Error('Not implemented yet.');
  }

  public styles(properties: ISelectorProperties): {[type: string]: any}  {
    throw new Error('styles not implemented in base class!');
  }

}
