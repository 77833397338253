import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  Renderer,
  ViewChild,
  ViewEncapsulation
} from "@angular/core";
import {
  ActivatedRoute,
  Event as RouterEvent,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from "@angular/router";
import { QueryOptions } from "apollo-client";
import gql from "graphql-tag";
import * as jp from "jsonpath";

import { AppConfig } from "../app.config";
import {
  BrandService
} from "./../services/brand/brand.service";
import {
  DataService
} from "./../services/data/data.service";

/* tslint:disable */
declare let jQuery: any;
declare let Hammer: any;

const DASHBOARD_CONTAINER_CLASS: string = '.dashboard';
const SLIM_SCROLL_DIV_CLASS: string = '.slimScrollDiv';
const SN_RESIZE: string = 'sn:resize';

@Component({
  selector: 'layout',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './layout.template.html',
  styleUrls: ['./layout.styles.scss'],
  host: {
    '[class.nav-static]' : 'config.state["nav-static"]',
    '[class.chat-sidebar-opened]' : 'chatOpened',
    '[class.app]' : 'true',
    id: 'app'
  }
})
export class Layout implements OnInit {
  public rightSideNavConfig: any;
  public leftSideNavConfig: any;
  public topNavConfig: any;
  config: any;
  configFn: any;
  $sidebar: any;
  el: ElementRef;
  router: Router;
  chatOpened: boolean = false;
  @ViewChild('routerComponent', {static: true}) routerComponent: ElementRef;
  public $el: any;
  private fragment: string;

  constructor(config: AppConfig,
              el: ElementRef,
              router: Router,
              private renderer: Renderer,
              private ngZone: NgZone,
              private route: ActivatedRoute,
              private _dataService: DataService,
              private _brandService: BrandService
            ) {
    this.el = el;
    this.config = config.Configuration();
    this.configFn = config;
    this.router = router;
    this.$el = jQuery(el.nativeElement);

  }

  toggleSidebarListener(state: any): void {
    let toggleNavigation = state === 'static'
      ? this.toggleNavigationState
      : this.toggleNavigationCollapseState;
    toggleNavigation.apply(this);
    localStorage.setItem('nav-static', this.config.state['nav-static']);
  }

  toggleChatListener(): void {
    jQuery(this.el.nativeElement).find('.chat-notification-sing').remove();
    this.chatOpened = !this.chatOpened;
  }

  toggleNavigationState(): void {
    this.config.state['nav-static'] = !this.config.state['nav-static'];
    if (!this.config.state['nav-static']) {
      this.collapseNavigation();
    }
  }

  expandNavigation(): void {
    // this method only makes sense for non-static navigation state
    if (this.isNavigationStatic()
      && (this.configFn.isScreen('lg') || this.configFn.isScreen('xl'))) { return; }

    jQuery('layout').removeClass('nav-collapsed');
    this.$sidebar.find('.active .active').closest('.collapse').collapse('show')
      .siblings('[data-toggle=collapse]').removeClass('collapsed');
  }

  collapseNavigation(): void {
    // this method only makes sense for non-static navigation state
    if (this.isNavigationStatic()
      && (this.configFn.isScreen('lg') || this.configFn.isScreen('xl'))) { return; }

    jQuery('layout').addClass('nav-collapsed');
    this.$sidebar.find('.collapse.in').collapse('hide')
      .siblings('[data-toggle=collapse]').addClass('collapsed');
  }

  /**
   * Check and set navigation collapse according to screen size and navigation state
   */
  checkNavigationState(): void {

    if (this.isNavigationStatic()) {
      if (this.configFn.isScreen('sm')
        || this.configFn.isScreen('xs') || this.configFn.isScreen('md')) {
        this.collapseNavigation();
      }
    } else {
      if (this.configFn.isScreen('lg') || this.configFn.isScreen('xl')) {
        setTimeout(() => {
          this.collapseNavigation();
        }, this.config.settings.navCollapseTimeout);
      } else {
        this.collapseNavigation();
      }
    }
  }

  isNavigationStatic(): boolean {
    return this.config.state['nav-static'] === true;
  }

  toggleNavigationCollapseState(): void {
    if (jQuery('layout').is('.nav-collapsed')) {
      this.expandNavigation();
    } else {
      this.collapseNavigation();
    }
  }

  _sidebarMouseEnter(): void {
    if (this.configFn.isScreen('lg') || this.configFn.isScreen('xl')) {
      this.expandNavigation();
    }
  }
  _sidebarMouseLeave(): void {
    if (this.configFn.isScreen('lg') || this.configFn.isScreen('xl')) {
      this.collapseNavigation();
    }
  }

  collapseNavIfSmallScreen(): void {
    if (this.configFn.isScreen('xs')
      || this.configFn.isScreen('sm') || this.configFn.isScreen('md')) {
      this.collapseNavigation();
    }
  }

  scrollToComponent() {
        if (!this.fragment) {
          return;
        }
        // this.fragment is ideally a componentID to scroll to.
        let element: any = document.getElementById(this.fragment);
        if (!element) {
          setTimeout(() => {
            this.scrollToComponent();
          }, 500);
          return;
        }
        element = $(element);
        setTimeout(() => {
          $('html, body').animate({scrollTop: element.offset().top - 120}, 'slow');
        }, 1250);
  }

  ngOnInit(): void {
    const queryOptions: QueryOptions = {
      query: gql`{ Configuration { NavigationBars { guid type config }}}`
    };
    this._dataService.runQuery("Layout Component", queryOptions).then((navsConfig: any) => {
      const navConfigurations = jp.query(navsConfig, "$..NavigationBars")[0]
      for(const nv of navConfigurations) {
        if (nv.type === 'right-nav-bar') {
          this.rightSideNavConfig = JSON.parse(nv.config);
        } else if(nv.type === 'left-nav-bar') {
          this.leftSideNavConfig = JSON.parse(nv.config);
        } else {
          this.topNavConfig = JSON.parse(nv.config);
        }
      }
    });
    if (localStorage.getItem('nav-static') === 'true') {
      this.config.state['nav-static'] = true;
    }

    let $el = jQuery(this.el.nativeElement);
    this.$sidebar = $el.find('ddc-left-sidebar');

    $el.find('a[href="#"]').on('click', (e: any) => {
      e.preventDefault();
    });

    this.$sidebar.on('mouseenter', this._sidebarMouseEnter.bind(this));
    this.$sidebar.on('mouseleave', this._sidebarMouseLeave.bind(this));

    this.checkNavigationState();

    this.$sidebar.on('click', () => {
      if (jQuery('layout').is('.nav-collapsed')) {
        if (this._brandService.isMobileDevice) {
          this.collapseNavigation();
        } else {
          this.expandNavigation();
        }
      }
    });

    this.route.fragment.subscribe(fragment => {
      this.fragment = fragment;
      if (!fragment) {
        return;
      }
      this.scrollToComponent();
    });

    this.router.events.subscribe((event) => {
      this._navigationInterceptor(event);
      this.collapseNavIfSmallScreen();
      window.scrollTo(0, 0);
    });



    this.$sidebar.find('.collapse').on('show.bs.collapse', function(e: any): void {
      // execute only if we're actually the .collapse element initiated event
      // return for bubbled events
      if (e.target !== e.currentTarget) { return; }

      let $triggerLink = jQuery(this).prev('[data-toggle=collapse]');
      jQuery($triggerLink.data('parent'))
        .find('.collapse.in').not(jQuery(this)).collapse('hide');
    })
    /* adding additional classes to navigation link li-parent
     for several purposes. see navigation styles */
      .on('show.bs.collapse', function(e: any): void {
        // execute only if we're actually the .collapse element initiated event
        // return for bubbled events
        if (e.target !== e.currentTarget) { return; }

        jQuery(this).closest('li').addClass('open');
      }).on('hide.bs.collapse', function(e: any): void {
      // execute only if we're actually the .collapse element initiated event
      // return for bubbled events
      if (e.target !== e.currentTarget) { return; }

      jQuery(this).closest('li').removeClass('open');
    });

  }

  private _navigationInterceptor(event: RouterEvent): void {

    if (event instanceof NavigationStart) {
      // We wanna run this function outside of Angular's zone to
      // bypass change detection
      this.ngZone.runOutsideAngular(() => {

        // For simplicity we are going to turn opacity on / off
        // you could add/remove a class for more advanced styling
        // and enter/leave animation of the spinner
        this.renderer.setElementStyle(
          this.routerComponent.nativeElement,
          'opacity',
          '0'
        );
      });
    }
    if (event instanceof NavigationEnd) {
      this._hideSpinner();
    }

    // Set loading state to false in both of the below events to
    // hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this._hideSpinner();
    }
    if (event instanceof NavigationError) {
      this._hideSpinner();
    }
  }

  private _hideSpinner(): void {
    // We wanna run this function outside of Angular's zone to
    // bypass change detection,
    this.ngZone.runOutsideAngular(() => {

      // For simplicity we are going to turn opacity on / off
      // you could add/remove a class for more advanced styling
      // and enter/leave animation of the spinner
      this.renderer.setElementStyle(
        this.routerComponent.nativeElement,
        'opacity',
        '1'
      );
    });
  }

  private initSlimScroll(): void {
    const $container = jQuery(DASHBOARD_CONTAINER_CLASS, this.$el);
    if (this.$el.find(SLIM_SCROLL_DIV_CLASS).length !== 0) {
      $container.slimscroll({
        destroy: true
      });
    }
    $container.slimscroll({
      height: window.innerHeight,
      width: '',
      size: '4px'
    });
  }
}
