import * as constants from "../../util/string-constants";
import { Logger } from "../logging/logger";
import { IExtent } from "./i-extent.interface";

const DEFAULT_MIN_VALUE: number = 0;
const DEFAULT_MAX_VALUE: number = 5;

export class RoundedExtentFormatter implements IExtent {

  constructor(private _config: any, private _logger: Logger) {
  }

  public getExtent(self: IExtent, seriesData: any,  _minValue?: number, _maxValue?: number, stacked?: boolean, forceMinToZero?: boolean): number[] {
    let yMin = Number.MAX_VALUE;
    let yMax = -Number.MAX_VALUE;
    if (stacked) {
      let values: any = [];
      for (const sd of seriesData) {
        const seriesDisabled: boolean = sd[constants.DISABLED];
        if (seriesDisabled === undefined || !seriesDisabled) {
          if (values.length > 0) {
            values = values.map((val: number, idx: number) => {
              if (null == sd.values[idx]) {
                return null;
              }
              const yVal = sd.values[idx].y;
              yMin = null != yVal ? Math.min(yMin, yVal): yMin;
              if (sd["ignoreCumulativeSum"]) {
                return Math.max(val, yVal);
              } else {
                return val + (yVal ? yVal : 0);
              }
            });
          } else {
            values = sd.values.map((val: any) => {
              if (null == val.y) {
                return val.y
              }
              yMin = Math.min(yMin, val.y);
              return val.y;
            });
          }
        }
      }
      if (forceMinToZero) {
        yMin = Math.min(yMin, 0);
      }
      yMax = Math.max.apply(yMax, values);
    } else {
      seriesData.forEach(function(ele: any) {
        const seriesDisabled: boolean = ele[constants.DISABLED];
        if (seriesDisabled === undefined || !seriesDisabled) {
          for (const val of ele[constants.VALUES]) {
            if (val.y !== null) {
              yMin = Math.min(yMin, val.y);
              yMax = Math.max(yMax, val.y);
            }
          }
        }
      });
      if (forceMinToZero) {
        yMin = Math.min(yMin, 0);
      }
    }

    if (yMin === 0 && yMax === 0) {
      return [yMin, yMax];
    }

    if (yMin === Number.MAX_VALUE) {
      yMin = DEFAULT_MIN_VALUE;
    }
    if (yMax === -Number.MAX_VALUE) {
      yMax = DEFAULT_MAX_VALUE;
    }

    const yMaxAbs = Math.abs(yMax);
    const yMinAbs = Math.abs(yMin);

    let yMaxThreshold: any;
    let yMinThreshold: any;
    for (let i = this._config.thresholds.length - 1; i >= 0; i-- ) {

      if (yMaxThreshold && yMinThreshold) {
        break;
      }

      const threshold = this._config.thresholds[i];
      if (yMaxThreshold === undefined && yMaxAbs >= threshold[constants.THRESHOLD]) {
        yMaxThreshold = threshold;
      }

      if (yMinThreshold === undefined && yMinAbs >= threshold[constants.THRESHOLD]) {
        yMinThreshold = threshold;
      }
    }

    if (yMinThreshold  ===  undefined) {
      yMinThreshold = this._config.default;
    }

    if (yMaxThreshold ===  undefined) {
      yMaxThreshold = this._config.default;
    }
    const topPadding: number = yMaxThreshold[constants.PADDING_ABOVE];
    const bottomPadding: number = yMinThreshold[constants.PADDING_BELOW];
    let yMaxRounded: number;
    yMaxRounded = this._round2Max(yMax, topPadding);

    let yMinRounded: number = this._round2Min(yMin, bottomPadding);
    if (yMin > 0 && yMinRounded < 0) {
      yMinRounded = 0;
    }

    yMaxRounded =  Math.ceil(yMaxRounded / topPadding) * topPadding;
    yMinRounded = Math.floor(yMinRounded / bottomPadding) * bottomPadding;

    if (_maxValue !== undefined) {
      yMaxRounded = Math.min(yMaxRounded, _maxValue);
    }

    if (_minValue !== undefined) {
      yMinRounded = Math.max(yMinRounded, _minValue);
    }
    return [yMinRounded, yMaxRounded];
  }

  private _round2Max(value: number, padding: number) {
    if (value <= 0) {
      return 0;
    }
    while (padding >= value) {
      padding = 0.5 * padding;
    }
    return value + padding;
  }

  private _round2Min(value: number, padding: number) {
    while (Math.abs(value) < padding ) {
      padding = 0.5 * padding;
    }
    return value - padding;
  }

}
