import { JsonObject, JsonProperty } from "json2typescript";

const GROUPS = "groups";
const ROLES = "roles";
const PERMISSIONS = "permissions";

@JsonObject
export class Authorization {
  @JsonProperty(GROUPS, [String], false)
  public Groups: string[] = [];

  @JsonProperty(ROLES, [String], false)
  public Roles: string[] = [];

  @JsonProperty(PERMISSIONS, [String], false)
  public Permissions: string[] = [];

}
