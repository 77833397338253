import * as OU from "../util/object-utils";
import { ADD, CLEAR, IDimension, IDimensionPayload, IDs, REMOVE, REPLACE } from "./dimension.actions";
import * as DimensionActions from "./dimension.actions";
import { ContextConfiguration } from "./config/context.config";

const LEVEL =  "level";

export interface DimensionContext {
  product: IDimension[];
  upsell: IDimension[];
  payments: IDimension[];
  prospect: IDimension[];
  customerKPI: IDimension[];
}

const initialState: DimensionContext = {
  product: [],
  upsell: [],
  payments: [],
  prospect: [],
  customerKPI: []
};

export function dimensionReducer(state = initialState, action: DimensionActions.DimensionActions): DimensionContext {
  switch (action.type) {
    case DimensionActions.DIMENSION_AGGREGATIONS_CHANGED:
      return updateDimensionAggregations(state, action.payload); // This will extend the given filter with
    case DimensionActions.DIMENSION_AGGREGATIONS_DEMO_RESET:
      return OU.copyObjectWithoutGraphQLMetaKeys(action.context) as DimensionContext;
    case DimensionActions.UPDATE_DIMENSIONS:
      return updateDimensions(state, action.payload);
    default:
      return state;
  }
}

function updateDimensionAggregations(currentState: DimensionContext, payload: IDimensionPayload): DimensionContext {

  // Can't use Array.slice().  All that does is return a new array.  It doesn't clone the items
  // IN the array.  The code which follows would be manipulating the current state array as well
  // and hence when you try to compare old and new state you will never see anything has changed.
  const dimensionLevels = JSON.parse((null != currentState[payload.type] ? JSON.stringify(currentState[payload.type]) : "[]"));

  let levelIndex = -1;
  const level = dimensionLevels.find((ele: any) => {
    levelIndex++;
    if (ele[LEVEL] === payload.level) {
      return ele;
    }
  });

  if (level === undefined) {
    dimensionLevels.push({
      level: payload.level,
      ids: [payload.id]
    });
  } else {
    const index = level[IDs].indexOf(payload.id);
    if (payload.type === ADD) {
      // If we don't find the element, we will add, else ignore.
      if (index === -1) {
        level[IDs].push(payload.id);
      }
    } else if (payload.type === REMOVE) {
      // If we find the element, we delete, else ignore.
      if (index > -1) {
        level[IDs].splice(index, 1);
      }
      if (level[IDs].length === 0) {
        dimensionLevels.splice(levelIndex, 1);
      }
    } else if (payload.type === REPLACE) {
      level[IDs][0] = payload.id;
    }
  }

  return {
    ...currentState,
    [payload.type]: dimensionLevels
  };
}

function updateDimensions(currentState: DimensionContext, payload: any): DimensionContext {
  // Can't use Array.slice().  All that does is return a new array.  It doesn't clone the items
  // IN the array.  The code which follows would be manipulating the current state array as well
  // and hence when you try to compare old and new state you will never see anything has changed.

  const dimensionalDependencies = ContextConfiguration.LOADED.dimensionalDependencies[payload.type];

  const addDependants: any[] = [];
  const removeDependants: any[] = [];

  const currentDimensionContext = JSON.parse(JSON.stringify(currentState[payload.type]));
  const payloadLevels: any = {};
  payload.actions.forEach((level: any) => {
    const template = { ...level.template };
    const data = level.data;
    const clear = template.clear;
    if (null == data && null == clear) {
      return;
    }
    let levelEle = currentDimensionContext.find((ele: any) => {
      if (ele[LEVEL] === template.level) {
        return ele;
      }
    });
    if (null == levelEle) {
      levelEle = payloadLevels[template.level];
    }
    switch (template.action) {
      case REPLACE: {
        delete template.action;
        if (data && (false !== clear) && levelEle && levelEle.ids.indexOf(data) !== -1 && template.toggle) {
          template.ids = [];
          if (null != dimensionalDependencies){
            if (template.level in dimensionalDependencies && data in dimensionalDependencies[template.level]) {
              const dependant = dimensionalDependencies[template.level][data];
              if (null != dependant) {
                removeDependants.push(dependant);
              }
            }
          }
        } else {
          if (null != dimensionalDependencies && template.level in dimensionalDependencies){
            const dependant = Object.keys(dimensionalDependencies[template.level]).filter((key) => key !== data)
              .map(a => dimensionalDependencies[template.level][a]);
            if (null != dependant) {
              removeDependants.push(...dependant);
            }
          }
          template.ids = Array.isArray(data) ? data : [data];
        }
        delete template.toggle;
        payloadLevels[template.level] = template;
        break;
      }
      case ADD: {
        delete template.action;

        let dependant;
        if (null != dimensionalDependencies && null != dimensionalDependencies[template.level]) {
          dependant = dimensionalDependencies[template.level][data];
        }

        if (data && levelEle) {
          const dataArr = Array.isArray(data) ? data : [data];
          for (const dataItem of dataArr) {
            const index = levelEle[IDs].indexOf(dataItem);
            if (index === -1) {
              levelEle.ids.push(dataItem);
              if (null != dependant) {
                addDependants.push(dependant);
              }
            } else if (template.toggle) {
              levelEle[IDs].splice(index, 1);
              if (null != dependant) {
                removeDependants.push(dependant);
              }
            }
            payloadLevels[template.level] = levelEle;
          }
        } else {
          if (null != dependant) {
            addDependants.push(dependant);
          }

          template.ids = data ? (Array.isArray(data) ? data : [data]) : [];
          payloadLevels[template.level] = template;
        }
        delete template.toggle;
        break;
      }
      case CLEAR: {
        delete template.action;
        template.ids = [];
        payloadLevels[template.level] = template;
      }
      default: {
        // Throw Error
      }
    }
  });
  // Compare Two Arrays and Adjust Missing to currentDimensionContext
  const missingDimensionContext = currentDimensionContext.filter(((ele: any) => payloadLevels[ele.level] === undefined));
  const payloadkeys = Object.keys(payloadLevels).filter((key: any) =>
    payloadLevels[key].ids.length > 0 && payloadLevels[key].ids.every((id: string) => id.length !== 0) && payloadLevels[key].clear !== true);

  const finalPayload = [...missingDimensionContext, ...payloadkeys.map((key: any) => payloadLevels[key])];
  removeDependants.forEach((removeDependant: any) => {
    finalPayload.forEach((ele: any, eleIdx: number) => {
      if (ele[LEVEL] === removeDependant.level) {
        const newIds = ele.ids.filter((id: any) => removeDependant.ids.indexOf(id) === -1 );
        if (newIds.length === 0) {
          finalPayload.splice(eleIdx, 1);
        } else {
          finalPayload[eleIdx] = {level: ele.level, ids: newIds};
        }
      }
    });
  });

  addDependants.forEach((dependant) => {
    const levelEleIdx = finalPayload.findIndex((ele: any) => dependant.level === ele.level);
    if (levelEleIdx === -1) {
      finalPayload.push(dependant);
    } else {
      finalPayload[levelEleIdx].ids.push(...dependant.ids);
    }
  });

  return {
    ...currentState,
    [payload.type]: finalPayload
  };
}
