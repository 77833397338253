import * as GQL from "../util/graphql-tags";

const TYPE_STRING = "string";
const TYPE_NUMBER = "number";
const TYPE_BOOLEAN = "boolean";

const GRAPHQL_STRIP_KEYS: string[] = [ GQL.META_TYPE_NAME ];

function copyObjectWithoutNullsOrGraphQLMetaKeys(copyFrom: object) {
  return copyObjectWithoutNulls(copyFrom, GRAPHQL_STRIP_KEYS);
}

function copyObjectWithoutGraphQLMetaKeys(copyFrom: object) {
  return copyObject(copyFrom, GRAPHQL_STRIP_KEYS, true);
}

function copyObjectWithoutNulls(copyFrom: object, keysToStrip?: string[]): object {
  return copyObject(copyFrom, keysToStrip, false);
}

function copyObject(copyFrom: object, keysToStrip: string[] = [], preserveNullValues: boolean = true): object {

  // This handles recursion so if we are called recursively and are passed a
  // string, we treat it like it's base type and return a copy of it
  // immediately.
  // Otherwise when you then call getOwnPropertyNames on one of these
  // primitive types you get strange behaviour like strings being turned into
  // character arrays which we dont want!
  if (typeof copyFrom === TYPE_STRING || typeof copyFrom === TYPE_NUMBER || typeof copyFrom === TYPE_BOOLEAN || Array.isArray(copyFrom)) {
    return copyFrom;
  }

  if (!copyFrom) {
    return null;
  }

  const ret: object = {};
  for (const key of Object.getOwnPropertyNames(copyFrom).filter((keyName) => keysToStrip.indexOf(keyName) === -1)) {
    const item: object = copyFrom[key];

    if (item != null || preserveNullValues) {
      if (Array.isArray(item)) {
        // Loop over the contents of the array and make a recursive call.
        ret[key] = item.map((arrayItem) => {
          return copyObject(arrayItem, keysToStrip, preserveNullValues);
        });
      } else {
        // Recursive call with a complex or primitive type will be handled
        // correctly as primitive handling happens first, so no need to test
        // for type here, just make a recursive call.
        ret[key] = copyObject(item, keysToStrip, preserveNullValues);
      }
    }
  }
  return ret;
}

/**
 * Simple camel case conversion.  Converts the first character
 * to upper case and all other characters to lower case.
 * (so if you expect it to capitalize other letters in the middle you'll be dissapointed!)
 * @param inVal The value to convert.
 */
function toSimpleCamelCase(inVal: string): string {
  if (null == inVal) {
    return null;
  } else if (inVal.length === 0) {
    return inVal;
  }
  let ret: string = (inVal.slice(0, 1) as string).toUpperCase();
  if (inVal.length > 1) {
    ret += (inVal.slice(1) as string).toLowerCase();
  }
  return ret;
}

function object2String(obj: {[type: string]: any}, keyValSeperator: string, seperator: string): string {
  return Object.keys(obj).map((key: any) => `${key}${keyValSeperator} ${obj[key]}`).join(seperator);
}

export {
  copyObjectWithoutNullsOrGraphQLMetaKeys,
  copyObjectWithoutGraphQLMetaKeys,
  copyObjectWithoutNulls,
  copyObject,
  object2String,
  toSimpleCamelCase
};
