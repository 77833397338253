
import * as GQL from "../util/graphql-tags";
import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class LocationType {
  @JsonProperty(GQL.SCALAR_ID, String, false)
  public id: string = undefined;

  @JsonProperty(GQL.SCALAR_GUID, String, false)
  public guid: string = undefined;

  @JsonProperty(GQL.SCALAR_NAME_REF, String, true)
  public nameRef: string = undefined;
}
