import { JsonConverter, JsonCustomConvert } from "json2typescript";

export enum NullValueRule {
  Any = "Any",
  All = "All"
}


@JsonConverter
export class NullValueRuleConverter implements JsonCustomConvert<NullValueRule> {
  public serialize(data: NullValueRule): any {
    return data ? data.toString() : "";
  }

  public deserialize(val: any): NullValueRule {
    return (NullValueRule[val] as any) as NullValueRule;
  }
}
