import { Apollo } from "apollo-angular";
import {
  HttpLink
} from "apollo-angular-link-http";
import {
  InMemoryCache,
  InMemoryCacheConfig,
  IntrospectionFragmentMatcher
} from "apollo-cache-inmemory";
import ApolloClient from "apollo-client";
import { setContext } from "apollo-link-context";

import { getJWT } from "../auth/auth-utils";
import introspectionConfiguration from "./fragmentTypes.json";

let _client: ApolloClient<any>;

function createApolloClient(
    apollo: Apollo,
    httpLink: HttpLink
) {
  const http = httpLink.create({
    uri: process.env.DDC_GQL_SERVER_URL
  });

  const middleware = setContext((_, { headers }) => {
    const ret = {
      headers: {
        ...headers,
        authorization: `Bearer ${getJWT()}`,
        uniqueId: Date.now().toString()
      }
    };
    return ret;
  });

  const link = middleware.concat(http);

  const fragmentMatcher = new IntrospectionFragmentMatcher(
    introspectionConfiguration
  );

  const cacheConfig: InMemoryCacheConfig = {
    dataIdFromObject: ((o: any) => {
      if (o["guid"]) {
        return o["guid"];
      }

      if (o["id"] && o["__typename"]) {
        return `${o["__typename"]}:${o["id"]}`;
      }
      // console.log(o);
      return null;
    }),
    addTypename: true,
    fragmentMatcher,
    cacheRedirects: {}
  };

  const cache = new InMemoryCache(cacheConfig);

  apollo.create({
    link,
    cache,
    ssrMode: true,
    ssrForceFetchDelay: 100,
    connectToDevTools: true,
    queryDeduplication: true
  });

  _client = apollo.getClient();

  return apollo;
}

async function resetApolloClientStore() {
  try {
    await _client.cache.reset();
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
}

export {
  createApolloClient,
  resetApolloClientStore
};
