import {
  JsonConvert,
  OperationMode,
  ValueCheckingMode
} from "json2typescript";

function _converter(): JsonConvert {
  const jsonConvert = new JsonConvert();
  jsonConvert.operationMode = OperationMode.ENABLE;
  jsonConvert.ignorePrimitiveChecks = false; // don't allow assigning number to string etc.
  jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL; // never allow null
  return jsonConvert;
}

export function json2ts(json: any, convertTo: any): any {
  return _converter().deserialize(json, convertTo);
}

export function ts2json(data: any): any {
  return _converter().serialize(data);
}

export function tsArray2json(data: any[]): any {
  return _converter().serializeArray(data);
}

export function json2tsArray(json: any, convertTo: any): any {
  return _converter().deserializeArray(json, convertTo);
}
