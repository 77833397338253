import { TooltipBase } from "./base-tooltip";

import { IFormatter } from '../formatter/i-formatter.interface';
import * as OU from '../../util/object-utils';

import {
  ISelectorProperties
} from "../brand/selector.properties.interface";

const LABELS = "group-labels";
const COLON_SPACE = ": ";
const SEMI_COLON = ";";
const BRAND_SELECTOR = "brand-selector";
const TOOLTIP_BRAND_SELECTOR = "tooltip-brand-selector";
const TOOLTIP_POPUP_TABLE_KEY = "tooltip-popup-table-key";

export class DimensionHorizontalBarChartTooltip extends TooltipBase {
  /*
  * Have to modify nvd3 code to tell us if the series isArea, on hover, so that
  * we can change the shape of bullet based on line type.
  */

  public generateTooltipContent(data: any, params?: any): string {
    this._logService.debug("Generating Dimension Horizontal Bar ToolTip of data ", {tooltipData: data, parameter: params});
    const brandSelectorConfig = this._config[BRAND_SELECTOR];
    const tooltipStyleBrandSelector = brandSelectorConfig[TOOLTIP_BRAND_SELECTOR];
    const seriesData = data.series[0];
    const showZeroValues = this._config["show-zero-values"] === undefined ? true : this._config["show-zero-values"];
    let translatedName;
    const seriesKeyName = data.data.name || data.data.key;
    const metaDataArray = data.data.meta;
    const metaData = metaDataArray.map((s: any) => Object.assign({}, s));
    let dataLength: number = metaData.length;
    if (!showZeroValues) {
      dataLength = 0;
      for (const d of metaData) {
        if (d.value && d.value > 0) {
          dataLength += 1;
        }
      }
    }
    metaData.sort((val1: {[id: string]: any}, val2: {[id: string]: any}) => {
      if (val1.id === "All") {
        return 1;
      }else if (val2.id === "All") {
        return -1;
      }
      return val2.value - val1.value ;
    });

    let dataFormatter: IFormatter;
    let percentFormatter: IFormatter;
    if (params) {
      if (params.formatter) {
        dataFormatter = this._formatterService.formatter(params.formatter);
      }
      if (params.percentFormatter) {
        percentFormatter = this._formatterService.formatter(params.percentFormatter);
      }
      if (params.titleTranslationKey) {
        translatedName = this._translateService.instant(params.titleTranslationKey);
      } else {
        translatedName = this._translateService.instant(this._config[LABELS][data.index]);
      }

    }
    const textStyle = this._getStyleFromBrandSelector(tooltipStyleBrandSelector, brandSelectorConfig[TOOLTIP_POPUP_TABLE_KEY]);
    let popupHTML = "";
    popupHTML += `<table style="width: 95%; max-width: 290px; font-variant: small-caps;"><thead><tr style="${textStyle}"><td colspan="4"> `;
    popupHTML += translatedName;
    popupHTML += '</strong></td></tr>';
    if (metaDataArray.length > 1 && (data.data.other === undefined || data.data.other)) {
      popupHTML += `<tr style="${textStyle}"><td colspan="4"> ` + seriesKeyName + `</strong></td></tr>`;
    }
    popupHTML += '</thead><tbody>';

    if (dataLength > 1) {
      const dimensionTotalValue = dataFormatter ? dataFormatter.formatData(data.data.totalValue) : data.data.totalValue;
      metaData.forEach((meta: any, index: number) => {
        if (!showZeroValues && (meta.value === 0 || meta.value === null)) {
          return;
        }
        const percentageShare = (meta.value / data.data.totalValue) * 100 ;
        const formattedPercentage = percentFormatter.formatData(percentageShare);
        const dimensionValue = dataFormatter ? dataFormatter.formatData(meta.value) : meta.value;
        let dimensionName = meta.name;
        if (index === metaData.length - 1) {
          dimensionName = "Other " + dimensionName;
        }
        let hoveredStyle = "";
        if (meta.id === seriesData.key || (seriesData.key === "All" && meta.name === data.data.name)) {
          hoveredStyle = "border-bottom-style: solid; border-top-style: solid; border-width:1px; border-color:" + meta.colour + ';';
        }
        popupHTML += '<tr class="store-popup-text" style="' + hoveredStyle + '"><td align="right" class="legend-icon legend-color-guide-area">';
        popupHTML += '<div style="background-color: ' + meta.colour;
        popupHTML += '; margin-top:2px"></div></td><td nowrap="wrap" width="140px" style="color:#555555; text-align: left;" class="key">';
        popupHTML += dimensionName + '</td><td style="color:#555555;text-align:right;" class="value">';
        popupHTML += dimensionValue + '</td><td style="color:#555555;text-align: right;">' + formattedPercentage + '</td></tr>';
      });
      popupHTML += '<tr><td></td><td class="value">Total: </td><td class="value">' + dimensionTotalValue + '</td></tr>';
    } else {
      const dimensionValue = dataFormatter ? dataFormatter.formatData(data.data.totalValue) : data.data.totalValue;
      popupHTML += '<tr><td align="right" class="legend-icon legend-color-guide-area"><div style="background-color: ';
      popupHTML += seriesData.color + '; margin-top:2px">';
      popupHTML += '</div></td><td>' + seriesKeyName + '</td><td class="value">' + dimensionValue + '</td></tr>';
    }
    popupHTML += '</tbody></table>';
    return popupHTML;
  }

  private _getStyleFromBrandSelector(brandSelector: string, key: string): string {
    const bStyle = this._brandService.styleSelector(brandSelector);
    const props: ISelectorProperties = {id: key};
    return OU.object2String(bStyle.styles(props), COLON_SPACE, SEMI_COLON);
  }

}
