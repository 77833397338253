import { Injectable, ErrorHandler } from "@angular/core";
import { LoggingService } from "./logging.service";

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  constructor(private _loggingService: LoggingService) {
    super();
  }

  public handleError(error: any) {
    this._loggingService.error(error);
  }
}
