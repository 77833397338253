import { BusinessDate } from './business-date.model';
import { BusinessDateOffset } from './business-date-offset.model';
import { JsonProperty, JsonObject } from 'json2typescript';

import * as GQL from "../../util/graphql-tags";
import { json2ts } from "../../util/json-2ts";
import { Order } from "../order.enum";
import { BusinessDateRangeCompareElements } from "./business-date-range-compare-elements.enum";
import { BusinessDateRangeDictionary } from "../../context/temporal.reducers";

@JsonObject
export class BusinessDateRange {

  public static ToValidInputObject(
    toStrip: BusinessDateRange,
    removeFromToIfLastNext: boolean = true
  ): BusinessDateRange {
    if (null == toStrip) {
      return null;
    }
    // console.log("TVIO starting with", toStrip);
    const ret: BusinessDateRange = BusinessDateRange.clone(toStrip);
    [GQL.FROM, GQL.TO].forEach((tag) => {
      const toClean: any = ret[tag];
      if (null != toClean) {
        // delete toClean[GQL.CALENDAR_DATE_RANGE];
        ret[tag] = BusinessDate.ToValidInputObject(toClean);
      }
    });

    // console.log("TVIO after TVIO on members", ret);

    if (!removeFromToIfLastNext) {
      return ret;
    }

    if (BusinessDateOffset.IsValidOffset(ret.Last)) {
      delete ret[GQL.FROM];
    } else {
      delete ret[GQL.LAST];
    }
    if (BusinessDateOffset.IsValidOffset(ret.Next)) {
      delete ret[GQL.TO];
    } else {
      delete ret[GQL.NEXT];
    }
    return ret;
  }

  public static ToValidContextObject(toClean: BusinessDateRange, convertToAbsolute = false): BusinessDateRange {
    let ret = BusinessDateRange.clone(toClean);
    if (convertToAbsolute) {
      ret = BusinessDateRange.toFromTo(ret);
    }
    return ret;
  }

  public static ToValidContextObjects(toClean: BusinessDateRangeDictionary): BusinessDateRangeDictionary {
    if (null == toClean) {
      return null;
    }
    return Object.keys(toClean).reduce((dict, curr) => {
      dict[curr] = BusinessDateRange.ToValidContextObject(toClean[curr]);
      return dict;
    }, {});
  }

  public static toFromTo(range: BusinessDateRange): BusinessDateRange {
    if (null == range) {
      return null;
    }
    return {
      From: range.From,
      To: range.To
    };
  }

  public static toLastNext(range: BusinessDateRange): BusinessDateRange {
    return {
      Last: range.Last,
      Next: range.Next
    };
  }

  public static clone(toClone: BusinessDateRange): BusinessDateRange {
    if (null == toClone) {
      return null;
    }
    return json2ts(JSON.parse(JSON.stringify(toClone)), BusinessDateRange);
  }

  public static IsValid(range: BusinessDateRange): boolean {
    return  (BusinessDateOffset.IsValidOffset(range.Last) || BusinessDate.IsValid(range.From)) &&
            (BusinessDateOffset.IsValidOffset(range.Next) || BusinessDate.IsValid(range.To));
  }

  public static IsAbsolute(range: BusinessDateRange): boolean {
    if (null == range) {
      return false;
    }
    return (!BusinessDateOffset.IsValidOffset(range.Last) || !BusinessDateOffset.IsValidOffset(range.Next))
    && BusinessDate.IsValid(range.From) && BusinessDate.IsValid(range.To);
  }

  public static compare(
    a: BusinessDateRange,
    b: BusinessDateRange,
    sortOrder: Order = Order.Ascending,
    compareElements: BusinessDateRangeCompareElements = BusinessDateRangeCompareElements.FromTo
  ): number {
    if (null == b && null != a) {
      // Something comes before nothing (unless sorting backwards).
      return 1 * sortOrder;
    } else if (null != b && null == a) {
      // Something comes before nothing unless sorting backwards.
      return -1 * sortOrder;
    } else if ( null == a && null == b) {
      return 0;
    }

    let compare: number;

    if (compareElements === BusinessDateRangeCompareElements.LastNext || compareElements === BusinessDateRangeCompareElements.All) {
      compare = BusinessDateOffset.compare(a.Last, b.Last, sortOrder);
      if (compare !== 0) {
        return compare;
      }

      compare = BusinessDateOffset.compare(a.Next, b.Next, sortOrder);
      if (compare !== 0) {
        return compare;
      }
    }

    if (compareElements === BusinessDateRangeCompareElements.FromTo || compareElements === BusinessDateRangeCompareElements.All) {
      compare = BusinessDate.compare(a.From, b.From, sortOrder);
      if (compare !== 0) {
        return compare;
      }

      return BusinessDate.compare(a.To, b.To, sortOrder);
    }

    return 0;
  }

  public static equals(
    a: BusinessDateRange,
    b: BusinessDateRange,
    compareElements: BusinessDateRangeCompareElements = BusinessDateRangeCompareElements.All
  ): boolean {
    if (null == a || null == b) {
      return false;
    }
    return BusinessDateRange.compare(a, b, Order.Ascending, compareElements) === 0;
  }

  @JsonProperty(GQL.FROM, BusinessDate, true)
  public From?: BusinessDate = undefined;

  @JsonProperty(GQL.TO, BusinessDate, true)
  public To?: BusinessDate = undefined;

  @JsonProperty(GQL.LAST, BusinessDateOffset, true)
  public Last?: BusinessDateOffset = undefined;

  @JsonProperty(GQL.NEXT, BusinessDateOffset, true)
  public Next?: BusinessDateOffset = undefined;

}
