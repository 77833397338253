import * as constants from "./string-constants";


export function translateUsingSuffix(nameRef: string, translationSuffix: string, translateService: any) {
  if (null != nameRef && nameRef !== constants.EMPTY) {
    let translationKey: string;
    if (null != translationSuffix) {
      translationKey = nameRef + constants.DOT + translationSuffix;
    } else {
      translationKey = nameRef;
    }
    const translatedDimensionName = translateService.instant(translationKey);
    if (translatedDimensionName !== translationKey) {
      nameRef = translatedDimensionName;
    }
  }
  return nameRef;
}
