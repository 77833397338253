import {
  TranslateService
} from "@ngx-translate/core";

import { Logger } from "../logging/logger";
import {
  FormatterService
} from "./formatter.service";
import {
  IFormatter
} from "./i-formatter.interface";

/*
* Time format examples - https://bl.ocks.org/zanarmstrong/ca0adb7e426c12c06a95
*/
export class DefaultFormatter implements IFormatter {

  private userLocale: any;

  constructor(locale: any, config: any, translateService: TranslateService, formatterService: FormatterService, logger: Logger) {
    this.userLocale = locale;
  }

  public formatData(datum: any, me: IFormatter): string {
    return datum;
  }
}
