import {
  TranslateLoader
} from "@ngx-translate/core";
import { Apollo } from "apollo-angular";
import { QueryOptions } from "apollo-client";
import gql from "graphql-tag";
import * as jp from "jsonpath";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { Logger } from "../logging/logger";
import {
  LoggingService
} from "../logging/logging.service";

const LOCALIZED_STRINGS_PATH = "$..LocalizedStrings.strings";
const LOG_NAMESPACE = "CustomGraphQLTranslateLoader";

export class CustomGraphQLTranslateLoader implements TranslateLoader  {

  private _logger: Logger;

  constructor(private _apollo: Apollo, private loggingService: LoggingService) {
    this._logger = new Logger(LOG_NAMESPACE, loggingService);
  }

  /**
   * Gets the translations/localized strings from the GQL server
   * @param lang
   * @returns {Observable<any>}
   */
  // WARNING - DO NOT CHANGE THIS FUNCTION NAME - SAME FUNCTION WILL BE CALLED FROM ngx-translate LIBRARY
  public getTranslation(lang: string): Observable<any> {
    this._logger.info("Starting Translations Observable...");
    const queryOptions: QueryOptions = {
      query: gql`{ Configuration { LocalizedStrings(id: "${lang}") { id strings }}}`
    };
    return this._apollo.query(queryOptions).pipe(map((res: any) => {
      return JSON.parse(jp.query(res, LOCALIZED_STRINGS_PATH)[0]);
    }));
  }
}
