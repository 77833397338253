// GENERAL

export const URL_SEP = "/";
export const URL_PARAM_START = "?";
export const URL_PARAM_SEP = "&";
export const UDEF: any = undefined;
export const URL: string = "url";
export const VALUES: string = "values";
export const SERIES_DEFINITION: string = "SeriesDefinition";
export const CLASSED: string = "classed";
export const LINE: string = "line";
export const DEFAULT_SERIES_GROUP: string = "DEFAULT";
export const GROUP: string = "group";
export const SERIES_ID: string = "seriesID";
export const SERIES_DEFINITION_REGEX: string = "series-definition-regex";
export const FUNCTION: string = "function";
export const CONFIG: string = "config";
export const BAR: string = "bar";
export const CHART_DATA: string = "chartData";
export const PIECHART: string = "pieChart";
export const KEY: string = "key";
export const NV_PIE_TITLE: string = "nv-pie-title";
export const TEXT: string = "text";
export const STYLES: string = "styles";
export const MIDDLE: string = "middle";
export const COLOUR: string = "colour";
export const COLOR: string = "color";
export const MAX_HEIGHT: string = "max-height";
export const OVERFLOW_Y: string = "overflow-y";
export const SCROLL: string = "scroll";
export const TOOLTIP: string = "tooltip";
export const UPDATED: string = "updated";
export const DELETED: string = "deleted";
export const ADDED: string = "added";

export const CENTERED_VALUES: string = "centeredValues ";
export const ATTRIBUTES: string = "attributes";
export const DEFAULT_BACKGROUND_COLOUR: string = "defaultBackgroundColor";
export const ORIENTATION_BOTTOM: string = "bottom";
export const ORIENTATION_LEFT: string = "left";
export const ORIENTATION_RIGHT: string = "right";
export const ORIENTATION_TOP: string = "top";
export const GENERAL_PATH: string = "GENERAL.";
export const SUFFIX_PATH: string = GENERAL_PATH + "SUFFIXES.";
export const SERIES_PATH: string = "SERIES.";
export const SERIES_DEFINITION_PLACEHOLDER: string = "{{{SERIES-DEFINITION-PLACEHOLDER}}}";
export const BENCHMARK: string = "benchmark";
export const AGGREGATION: string = "Aggregation";
export const COALESCE: string = "Coalesce";
export const CUSTOM_ID: string = "customID";
export const MISSING_IMPLEMENTATION_ERROR: string = "Implementation for {{{REFERENCE}}} is not found in child class.";
export const TITLE = "title";

export const ELEMENT_CLICK = "elementClick";
export const ELEMENT_DBL_CLICK = "elementDblclick";

export const RETURN_TYPE: string = "returnType";
export const REQUIRED_SELECTORS: string = "requiredSelectors";
export const STRING: string = "string";
export const NUMBER: string = "number";
export const BOOLEAN: string = "boolean";
export const DATA: string = "data";
export const PATH: string = "path";
export const PRIORITY: string = "priority";
export const IS_ROOT_QUERY: string = "isRootQuery";
export const ALL: string = "all";
export const QUERY: string = "query";
export const SELECTORS: string = "selectors";
export const YAXIS: string = "yAxis";
export const AREA: string = "area";
export const TYPE: string = "type";
export const ID: string = "id";
export const CLIP_PATH = "clip-path";
export const TRANSFORM = "transform";
export const PADDING_ABOVE: string = "padding-above";
export const PADDING_BELOW: string = "padding-below";
export const THRESHOLD: string = "threshold";
export const DISABLED: string = "disabled";
export const VALUE: string = "value";
export const NOT_AVAILABLE: string = "NOT-AVAILABLE";
export const META: string = "meta";
export const PILL_STYLE: string = "pill-style";
export const BORDER: string = "border";
export const BACKGROUND: string = "background";
export const WHITE: string = "white";
export const PILLID: string = "pillID";
export const REMOVE_CAPS: string = "REMOVE";
export const CONTEXT: string = "context";
export const NAMESPACE: string = "namespace";
export const LOG_LEVEL: string = "logLevel";
export const LEVEL: string = "level";
export const MESSAGE: string = "message";
export const GUID: string = "guid";
export const LABEL: string = "label";
export const SHORT_LABEL: string = "shortLabel";
export const ADDITIONAL_META_VALUE: string = "additionalMeta";
export const DEFAULT = "default";
export const CSV = "csv";
export const PDF = "pdf";

// CONTEXT
export const CONTEXT_REFERENCE = "reference";
export const LOCATION_ID = "locationID";
export const REGION_ID = "regionID";
export const LOCATION_TYPE_ID = "locationTypeID";
export const ENTITY_ID = "entityID";
export const PERSON_ID = "personID";
export const ACTIVE_SNAPSHOT = "ActiveSnapshot";
export const DEPARTMENT_ID = "departmentID";
export const PARTNER_ID = "partnerID";
export const CONTEXT_TEMPORAL = "temporal";
export const CONTEXT_DIMENSION = "dimension";
export const CONTEXT_TEMPORAL_RANGE_BY_TYPE = "rangesByType";
export const CONTEXT_L4L = "L4L";
export const CONTEXT_UX = "ux";
export const CONTEXT_TEMPORAL_ABSOLUTE = "absolute";
export const CONTEXT_TEMPORAL_CURRENT = "current";
export const CONTEXT_USER = "user";
export const CONTEXT_DEMO = "demo";
export const REGISTRATION = "registration";
export const KEYS = "keys";
export const NO_DATA: string = "no-data-translation-key";
export const NO_WEATHER_DATA: string = "noWeatherTranslationKey";
export const USE_CONFIG_INDEX: string = "use-config-index";

// COMPONENTS
export const TARGET_TITLE = "Title";
export const SECONDARY_AXIS = "secondary-axis";

// SPECIAL CHARS
export const ASTERISK: string = "*";
export const COLON: string = ":";
export const SPACE: string = " ";
export const COLON_SPACE: string = COLON + SPACE;
export const SEMI_COLON: string = ";";
export const DOT: string = ".";
export const EMPTY: string = "";
export const HASH: string = "#";
export const COMMA: string = ",";
export const COMMA_SPACE: string = COMMA + SPACE;
export const NEWLINE: string = "\n";
export const PIPE: string = "|";
export const DASH: string = "-";
export const SLASH: string = "/";
export const DOUBLE_SLASH: string = SLASH + SLASH;
export const LEFT_ROUND_BRACKET: string = "(";
export const RIGHT_ROUND_BRACKET: string = ")";

// CSS
export const ABSOLUTE: string = "absolute";
export const HEIGHT: string = "height";
export const WIDTH: string = "width";
export const FILL: string = "fill";
export const OPACITY: string = "opacity";
export const FILL_OPACITY: string = `${FILL}-${OPACITY}`;
export const TEXT_ANCHOR: string = "text-anchor";
export const FONT_SIZE: string = "font-size";
export const DISPLAY: string = "display";
export const NONE: string = "none";
export const BLOCK: string = "block";
export const STYLE: string = "style";
export const BACKGROUND_COLOR: string = "background-color";
export const BORDER_RADIUS: string = "border-radius";
export const BOX_SHADOW: string = "box-shadow";
export const MARGIN: string = "margin";
export const POSITION: string = "position";
export const STICKY: string = "sticky";
export const LEFT: string = "left";

// CSS CLASSES
export const LEFT_AXIS_LABEL_CLASS = ".nv-y1 .nv-axislabel";
export const RIGHT_AXIS_LABEL_CLASS = ".nv-y2 .nv-axislabel";
export const BOTTOM_AXIS_LABEL_CLASS = ".nv-x .nv-axislabel";

// ATTRIBUTES
export const X: string = "x";
export const Y: string = "y";
export const CLASS: string = "class";
export const DY: string = "dy";
export const ALIGNMENT_BASELINE: string = "alignment-baseline";
export const CHECKED: string = "checked";
export const COLSPAN: string = "colspan";
export const ROWSPAN: string = "rowspan";

// HTML TAGS
export const TH: string = "th";
export const ALIGN_LEFT: string = "left";
export const ALIGN_RIGHT: string = "right";
export const EVENTS_NONE: string = "none";
export const EVENTS_ALL: string = "all";
export const BODY: string = "body";
export const SVG_TAG: string = "svg";
export const DIV_TAG: string = "div";
export const STROKE: string = "stroke";
export const STROKE_WIDTH: string = `${STROKE}-width`;
export const POINTER_EVENTS: string = "pointer-events";
export const TARGET: string = "target";
export const _BLANK: string = "_blank";
export const TEXT_AREA: string = "textarea";
export const IFRAME: string = "iframe";
export const ANCHOR: string = "a";
export const FORM: string = "form";
export const INPUT: string = "input";
export const HIDDEN: string = "hidden";
export const POST: string = "post";
export const BR: string = "br";
export const TWO_BRS: string = `<${BR}/><${BR}/>`

// BROWSER COMMANDS
export const CMD_COPY: string = "copy";

// HTTP AUTH HEADERS
export const HEADER_BEARER: string = "Bearer";

// CLASSES
export const NV_AREA_CLASS: string = ".nv-area";

// LEGEND EVENTS
export const LEGEND_CLICK: string = "legendClick";

// COLOURS
export const LITE_GRAY_COLOUR: string = "#fff";

// REGEX
export const REGEX_GLOBAL_MATCH: string = "g";

// Touch Settings
export const longTouchDuration = 1875;
export const longTouchDelayDuration = 1000;

// Local Storage Prefix
export const LS_PREFIX = "ddc.context.";
export const LS_USER = LS_PREFIX + "user";
export const USER_RELOGIN = "ddc.user.relogin";

// ROUTES
export const APP_ROUTE = "/app";
export const LOGIN_ROUTE = "/login";
export const CONTINUE_ROUTE = LOGIN_ROUTE + "/continue";
export const DASHBOARD_ROUTE = APP_ROUTE + "/dashboard";
export const DEFAULT_ROUTE = DASHBOARD_ROUTE + "/0";


export const DEFAULT_AVATAR_URL = "assets/img/avatar.png";

// TEMPORAL SELECTOR
export const SHORTCUTS = "shortcuts";
export const OVERLAY = "overlay";

export const TKEY_BRAND_LOGO = "BRAND.LOGO";

// UNITS
export const PX = "px";

// MOUSE EVENTS
export const CLICK = "click";
export const TOUCHSTART = "touchstart";
export const TOUCHEND = "touchend";

// KEYBOARD EVENTS
export const KEYDOWN = "keydown";
export const ESCAPE_KEY_CODE = 27;

// Special characters
export const ELLIPSIS = "\u2026";

