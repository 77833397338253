
import * as GQL from "../util/graphql-tags";
import { JsonObject, JsonProperty } from 'json2typescript';
import { Region } from './region.model';
import { Geo } from './geo.model';
import { LocationType } from './location-type.model';

@JsonObject
export class Location {
  @JsonProperty(GQL.SCALAR_ID, String, false)
  public id: string = undefined;

  @JsonProperty(GQL.SCALAR_GUID, String, false)
  public guid: string = undefined;

  @JsonProperty(GQL.SCALAR_NAME, String, false)
  public name: string = undefined;

  @JsonProperty(GQL.GEO, Geo, true)
  public Geo: Geo = undefined;

  @JsonProperty(GQL.REGION, Region, true)
  public Region: Region = undefined;

  @JsonProperty(GQL.TYPE, LocationType, true)
  public Type: LocationType = undefined;
}
