import {
  JsonObject,
  JsonProperty
} from "json2typescript";

import * as GQL from "../../util/graphql-tags";
import { json2ts } from "../../util/json-2ts";
import { Order } from "../order.enum";
import {
  CalendarDateRange
} from "./calendar-date-range.model";
import {
  TemporalAggregation, TemporalAggregationGranularity
} from "./temporal-aggregation.enum";
import { TemporalAggregationConverter } from "../../context/config/temporal-aggregation-converters";
import { Event } from "../events/event.model";
import { CurrentBusinessDateDictionary } from "../../context/temporal.reducers";

@JsonObject
export class BusinessDate {

  public static IsValid(date: BusinessDate): boolean {
    if (null == date) {
      return false;
    }

    return null != date.year && !(
      null == date.quarter &&
      null == date.month &&
      null == date.week &&
      null == date.day &&
      null == date.hour &&
      null == date.minute &&
      null == date.second
      );
  }

  public static ToValidInputObject(toStrip: BusinessDate): BusinessDate {
    if (null == toStrip) {
      return null;
    }
    const ret: BusinessDate = JSON.parse(JSON.stringify(toStrip));
    if (null != ret) {
      delete ret[GQL.META_TYPE_NAME];
      delete ret[GQL.SCALAR_TYPE];
      delete ret[GQL.SCALAR_GUID];
      delete ret[GQL.CALENDAR_DATE_RANGE];
      delete ret[GQL.SCALAR_AS_STRING];
      delete ret[GQL.BUSINESS_DATE_RANGE];
      if (null != ret.Event) {
        ret[GQL.EVENT] = Event.ToValidInputObject(ret.Event)
      }
    }
    return ret;
  }

  public static ToValidContextObjects(toClean:CurrentBusinessDateDictionary) {
    if (null == toClean) {
      return null;
    }
    return Object.keys(toClean).reduce((dict, curr) => {
      dict[curr] = BusinessDate.ToValidContextObject(toClean[curr]);
      return dict;
    }, {});
  }

  public static ToValidContextObject(toClean: BusinessDate): BusinessDate {
    if (null == toClean) {
      return null;
    }
    const ret = BusinessDate.clone(toClean);
    delete ret[GQL.BUSINESS_DATE_RANGE];
    if (GQL.EVENT in ret) {
      ret[GQL.EVENT] = Event.ToValidContextObject(ret[GQL.EVENT])
    }
    return ret;
  }

  public static dateElementChanged(newDate: BusinessDate, oldDate: BusinessDate): string {
    let ret;
    if (undefined === oldDate) {
      return ret;
    }

    for (const toCheck of BusinessDate._DATE_ELEMENTS_ASC) {
      if (newDate[toCheck] && oldDate[toCheck] && newDate[toCheck] > oldDate[toCheck]) {
        ret = toCheck;
        break;
      }
    }

    return ret;

  }

  /**
   * A function which can be used to sort an array of business dates as it
   * meets the requires of Array.sort.compareFn.
   *
   * Note that this is only suitable for comparing business dates with the same
   * level of granularity.  At the moment it will NOT  determine
   * That 2016W15D3 is "greater than" 2016W15.  It will ignore elements of the
   * business date that don't exist in _both_ dates.
   *
   * Or it can easily be used to just do a one off compare of two dates.
   *
   * Will return -1 if date1 < date2 (date1 is an earlier date.)
   *
   * Will return 1 if date1 > date2 (date1 is a later date.)
   *
   * Will return 0 if date1 = date2 (date1 and date2 are the same date, to whatever
   * level of precision they contain.)
   * @param a The first of the two dates to compare.
   * @param b The second of the two dates to compare.
   */
  public static compare(a: BusinessDate, b: BusinessDate, sortDirection: Order = Order.Ascending): number {

    if (a != null && b == null) {
      return sortDirection;
    } else if (a == null && b != null) {
      return -1 * sortDirection;
    } else if (a == null && b == null) {
      return 0;
    }

    if (null != a.guid && null != b.guid && a.guid === b.guid) {
      return 0;
    }

    let ret: number = 0;

    for (const part of BusinessDate._DATE_ELEMENTS_DESC) {
      // Always compare using an ascending sort as the end of the for loop
      // will apply the sort order logic.  If you apply it twice, guess what,
      // you didn't apply it at all!  We're both reversing the polarity...
      // We're CONFUSING the polarity! :)
      ret = BusinessDate._compareBusinessDateElement(a, b, part, Order.Ascending);
      if (ret !== 0) {
        // We could return the actual difference but that would be a little misleading
        // since we don't say which element caused us to make the decision
        // (year, week, month etc.)
        // So better to just return -1 or 1 depending on the sign of the result.
        ret = ret < 0 ? -1 : 1;
        // If this is sufficient to distinguish the dates, then break
        // and return the value.  Otherwise we will need to look at the next,
        // more granular date component.
        break;
      }
    }
    // Sort according to desired direction.
    return ret * sortDirection;
  }

  public static equalAtLevel(a: BusinessDate, b: BusinessDate, compareAtLevel: TemporalAggregation): boolean {
    if (null == a || null == b) {
      return false;
    }

    const compareGranularity = TemporalAggregationGranularity[compareAtLevel];

    for (const part of BusinessDate._DATE_ELEMENTS_DESC) {
      const partGranularity = TemporalAggregationGranularity[part];
      if (partGranularity > compareGranularity) {
        return true;
      }
      const compare = BusinessDate._strictCompareBusinessDateElement(a, b, part, Order.Ascending);
      if (compare !== 0) {
        return false;
      }
    }
  }

  public static equals(a: BusinessDate, b: BusinessDate): boolean {

    if (null == a || null == b) {
      return false;
    }

    if (null != a.guid && null != b.guid) {
      // If they both have guids then either they're equal or they're not!
      // But if either one has no GUID then we fall back on a straight compare
      // of the date elements themselves.
      return a.guid === b.guid;
    }

    return BusinessDate.compare(a, b, Order.Ascending) === 0;
  }

  public static clone(toClone: BusinessDate): BusinessDate {
    if (null == toClone) {
      return null;
    }
    return json2ts(JSON.parse(JSON.stringify(toClone)), BusinessDate);
  }

  // Date elements in order of granularity from most granular to least.
  private static readonly _DATE_ELEMENTS_ASC: string[] = [
    GQL.SCALAR_SECOND,
    GQL.SCALAR_MINUTE,
    GQL.SCALAR_HOUR,
    GQL.SCALAR_DAY,
    GQL.SCALAR_WEEK,
    GQL.SCALAR_MONTH,
    GQL.SCALAR_QUARTER,
    GQL.SCALAR_YEAR
  ];

  // Opposite order.
  private static readonly _DATE_ELEMENTS_DESC: string[] = BusinessDate._DATE_ELEMENTS_ASC.slice().reverse();

  private static _strictCompareBusinessDateElement(a: BusinessDate, b: BusinessDate, propertyName: string, sortDirection: Order): number {
    if (null == a || null == b) {
      return null;
    } else if (null != a[propertyName] && null != b[propertyName]) {
      return BusinessDate._compareBusinessDateElement(a, b, propertyName, sortDirection);
    } else {
      return null;
    }
  }

  private static _compareBusinessDateElement(a: BusinessDate, b: BusinessDate, propertyName: string, sortDirection: Order): number {
    let compare: number = 0;
    if (null != a[propertyName] && null != b[propertyName]) {
      compare = a[propertyName] - b[propertyName];
    }

    return compare * sortDirection;
  }

  @JsonProperty(GQL.SCALAR_TYPE, TemporalAggregationConverter, true)
  public type: TemporalAggregation = TemporalAggregation.DEFAULT;

  @JsonProperty(GQL.SCALAR_GUID, String, true)
  public guid?: string = undefined;

  @JsonProperty(GQL.SCALAR_YEAR, Number, true)
  public year?: number = undefined;

  @JsonProperty(GQL.SCALAR_MONTH, Number, true)
  public month?: number = undefined;

  @JsonProperty(GQL.SCALAR_QUARTER, Number, true)
  public quarter?: number = undefined;

  @JsonProperty(GQL.SCALAR_WEEK, Number, true)
  public week?: number = undefined;

  @JsonProperty(GQL.SCALAR_DAY, Number, true)
  public day?: number = undefined;

  @JsonProperty(GQL.SCALAR_HOUR, Number, true)
  public hour?: number = undefined;

  @JsonProperty(GQL.SCALAR_MINUTE, Number, true)
  public minute?: number = undefined;

  @JsonProperty(GQL.SCALAR_SECOND, Number, true)
  public second?: number = undefined;

  @JsonProperty(GQL.CALENDAR_DATE_RANGE, CalendarDateRange, true)
  public CalendarDateRange?: CalendarDateRange = undefined;

  @JsonProperty(GQL.EVENT, Event, true)
  public Event?: Event = undefined;

}
