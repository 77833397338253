
import * as GQL from "../util/graphql-tags";
import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class Entity {
  @JsonProperty(GQL.SCALAR_ID, String, false)
  public id: string = undefined;

  @JsonProperty(GQL.SCALAR_NAME, String, false)
  public name: string = undefined;
}
